import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import UserContact from "./UserContact";
import axios from "axios";


export default function UserContacts() {

    const [loading, setLoading] = useState(true);
    const [contactsData, updateContactsData] = useState();

    window.updateContactsData = updateContactsData;

    useEffect(() => {

        axios.get(APIService.API_URL + "user_contacts/get",
            {headers: {"Authorization": APIService.getHeaders().authorization}})
            .then((response) => {

                if (response.data.errors) {
                    // do nothing
                } else {
                    sessionStorage.setItem("user_contacts", JSON.stringify(response.data.user_contacts));
                    updateContactsData(APIService.getContacts());
                    setLoading(false);
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    const onClickAddNewContact = () => {
        document.getElementById('add-contact-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a contact';
    };

    return (
        <div>
            <div className="d-flex">
                <div className="my-auto">
                    <h4 className="mb-0 page-title">Your contributors</h4>
                </div>
                <div className="ms-auto d-none d-md-block">
                    <button data-bs-toggle="modal" data-bs-target="#add-contact-modal" onClick={onClickAddNewContact}
                            className="btn btn-sm btn-outline-secondary button-text"
                            target="_blank"><i className="material-icons-outlined icon opacity-10">group</i>&nbsp; Add contributor
                    </button>
                </div>
            </div>
            <div className="row row mt-lg-4 mt-2">
                {contactsData && contactsData.length > 0 && <div className="table-responsive p-0">
                    <table className="table table-striped align-items-center mb-0">
                        <thead>
                        <tr>
                            <th className="">Contributor</th>
                            <th className="">Name</th>
                            <th className="text-center">Boards</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {contactsData.map((c, index) => (
                            <UserContact
                                data={c}
                                key={index}
                                index={index}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>}
                {(!contactsData || contactsData.length < 1) &&
                <p>You have no contacts added.</p>
                }
            </div>
        </div>
    );
};

