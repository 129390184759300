import React from 'react';
import Navbar from "../Dashboard/Navbar";
import Footer from "../Dashboard/Footer";
import {Helmet} from "react-helmet";

export default function Philosophy() {

    return (
        <div className="wrapper home other">
            <Helmet>
                <title>NARRA - The Philosophy</title>
            </Helmet>
            <Navbar showTourLink="false" auth_mode="unauth" activeMenu="home" />
            <main className="main">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 me-3 ms-auto wow fadeInLeft" data-wow-delay=".4s" data-wow-duration="2s">
                            <div className="brand-name">THE PHILOSOPHY</div>
                            <div className="brand-tagline">
                                <p>Celebrating the beauty and art of words.</p>
                                <p>Why I created NARRA.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-7 d-flex flex-column m-auto">

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p><span className="label">CONTEXT</span></p>

                            <p>Words are beautiful.</p>

                                <p>There’s something elegant about well constructed phrases and sentences.</p>

                                    <p>Great writing is not just great.</p>

                                        <p>It is art.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">WHY I WRITE</span></p>

                            <p>I love the creative process of painting pictures with words.</p>

                                <p>Telling stories.</p>

                                <p>Moving emotions.</p>

                                <p>Sparking ideas.</p>

                                <p>Motivating.</p>

                                <p>Inspiring.</p>

                                <p>It is why I write.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">THE PHILOSOPHY</span></p>

                            <p>Our emotions enjoy being tickled by multiple senses.</p>

                                <p>Images, words and sound.</p>

                                <p>By multiple spices and flavours.</p>

                                <p>But there’s a depth to the purity of one medium.</p>

                                <p>Of one flavour.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">THE PHILOSOPHY</span></p>

                            <p>NARRA is a canvas designed exclusively for words.</p>

                                <p>Helping create art with words.</p>

                                <p>And showcasing that art.</p>

                                <p>Only words.</p>

                                <p>No distracting images or videos or sound.</p>

                                <p>Simply words.</p>

                                <p>Elegant words.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">THE PHILOSOPHY</span></p>

                            <p>Words are beautiful.</p>

                                <p>Stories are beautiful.</p>

                                <p>Well written narratives are beautiful.</p>

                                <p>Art is beautiful.</p>

                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">CALL TO ACTION</span></p>

                            <p>Celebrate the art of words.</p>

                                <p>Paint your narrative.</p>

                                <p>NARRA.</p>
                            </div>


                        </div>
                    </div>
                </div>
                <Footer mode={'home'}/>
            </main>
        </div>
    );
}

