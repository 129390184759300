import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Swal from 'sweetalert2';

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function AddSuggestionModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [description, setDescription] = useState("");
    const [cardID, setCardID] = useState("");
    const [suggestionID, setSuggestionID] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const descrMaxChars = 150;

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("suggestion-description-char-count").innerText = e.target.value.length;
    };

    const onChangeCardID = (e) => {
        setCardID(e.target.value);
    };

    const onChangeSuggestionID = (e) => {
        setSuggestionID(e.target.value);
    };

    const onClickModalClose = () => {

        document.getElementById("suggestion-description-char-count").innerText = '0';

        setDescription("");
        setCardID("");
        setSuggestionID("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickSuggestionDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            html: '<span style="font-weight: 300;">Your suggestion will be permanently deleted.</span>',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            color: '#000000',
            customClass: {
                confirmButton: 'btn btn-outline-danger button-text m-1',
                title: 'swal-text',
                cancelButton: 'btn btn-outline-secondary button-text m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteCardSuggestion(suggestionID);
                onClickModalClose(true);
            }
        });
    }

    const setSuggestionModalFormFields = (data) => {

        setCardID(data.data.board_card_id);
        setSuggestionID(data.data.id);
        setDescription(data.data.description);

        document.getElementById("suggestion-description-char-count").innerText = data.data.description.length;

    }

    window.setSuggestionModalFormFields = setSuggestionModalFormFields;

    const addNewSuggestion = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "card_suggestions/add";
            var suggestion_data = {"card_suggestion_id": suggestionID, "card_suggestion": { "board_card_id": cardID, "description": description}};

            if(suggestionID !== ""){
                api_endpoint = "card_suggestions/update";
            }

            axios.post(APIService.API_URL + api_endpoint, suggestion_data,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                        APIService.resetBoardCards();
                        APIService.resetCardSuggestions();
                        document.getElementById('add-suggestion-modal-close').click();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            html: '<span style="font-weight: 300;">Your suggestion has been saved.</span>',
                            color: '#000000',
                            customClass: {
                                confirmButton: 'btn black-btn button-text m-1',
                                title: 'swal-text'
                            },
                            buttonsStyling: false
                        }).then((result) => {
                            onClickModalClose(true);
                        });

                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal fade" tabIndex="-1" role="dialog" id="add-suggestion-modal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewSuggestion} className="navbar-form" id="form-add-suggestion-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Add a suggestion</h5>
                            <button  id="add-suggestion-modal-close" className="close-btn btn btn-icon" type="button" data-bs-toggle="modal"
                                    data-bs-target="#show-suggestions-modal" data-bs-dismiss="modal" onClick={onClickModalClose}>
                                <i className="material-icons-round">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="card-id" value={cardID} onChange={onChangeCardID}/>
                            <input type="hidden" name="suggestion-id" value={suggestionID} onChange={onChangeSuggestionID}/>
                            <div className="mb-3">
                                    <label htmlFor="description" className="form-label w-100"><span
                                        className="label-text">Suggestion</span>
                                        <Textarea id="form-add-suggestion-description" name="description"
                                                  value={description} maxLength={descrMaxChars}
                                                  className="form-control" onChange={onChangeDescription}
                                                  placeholder="" rows="3"
                                                  validations={[required]}
                                        />
                                    </label>
                                <div className="sub-text float-right">
                                    <span id="suggestion-description-char-count">0</span> / {descrMaxChars} chars.
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm black-btn button-text" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {suggestionID && <button type="button" className="btn btn-sm btn-outline-danger button-text"  data-bs-toggle="modal"
                                                     data-bs-target="#show-suggestions-modal" data-bs-dismiss="modal"
                                    onClick={onClickSuggestionDelete}>Delete
                            </button>}

                            <button type="button" className="btn btn-sm btn-outline-secondary button-text"  data-bs-toggle="modal"
                                    data-bs-target="#show-suggestions-modal" data-bs-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}