import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Swal from 'sweetalert2';

export default function AddCardModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [cardID, setCardID] = useState("");
    const [text, setText] = useState("");
    const [boardToken, setBoardToken] = useState("");
    const [cardTypeID, setCardTypeID] = useState("");
    const [cardType, setCardType] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeCardID = (e) => {
        setCardID(e.target.value);
    };

    const onChangeBoardToken = (e) => {
        setBoardToken(e.target.value);
    };

    const onChangeCardTypeID = (e) => {
        setCardTypeID(e.target.value);
    };

    const onChangeText = (e) => {
        setText(e.target.value);
        var word_count = 0;
        if(e.target.value.trim() !== '') {
            word_count = e.target.value.trim().split(/\s+/).length;
        }
        document.getElementById("card-text-char-count").innerText = word_count;
    };

    function clearText(){
        setText('');
        document.getElementById("card-text-char-count").innerText = '0';
    }

    const onClickModalClose = () => {

        document.getElementById("card-text-char-count").innerText = '0';

        setCardID("");
        setBoardToken("");
        setCardTypeID("");
        setText("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const setCardModalFormFields = (data) => {

        var word_count = 0;
        if(data.text.trim() !== ''){
            word_count = data.text.trim().split(/\s+/).length;
        }
        document.getElementById("card-text-char-count").innerText = word_count;

        setCardID(data.id);
        setBoardToken(data.user_board_token);
        setCardTypeID(data.card_type_id);
        setCardType(data.card_type.label);
        setText(data.text);

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    }

    window.setCardModalFormFields = setCardModalFormFields;

    const addNewCard = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "board_cards/update";
            var card_data = {
                "board_card_id": cardID,
                "board_card": {
                    "text": text, "user_board_token": boardToken,
                    "image": "", "card_type_id": cardTypeID
                }
            }

            axios.post(APIService.API_URL + api_endpoint, card_data,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    APIService.resetBoardCards();
                    document.getElementById('add-card-modal-close').click();
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }


    return (
        <div className="modal fade" tabIndex="-1" role="dialog" id="add-card-modal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewCard} className="navbar-form" id="form-add-card-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit section</h5>
                            <button id="add-card-modal-close" className="close-btn btn btn-icon" type="button" data-bs-dismiss="modal" onClick={onClickModalClose}>
                                <i className="material-icons-round">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="card-id" value={cardID} onChange={onChangeCardID}/>
                            <input type="hidden" name="board-token" value={boardToken} onChange={onChangeBoardToken}/>
                            <input type="hidden" name="card-type-id" value={cardTypeID} onChange={onChangeCardTypeID}/>
                            <div className="mb-3">
                                <label htmlFor="text" className="form-label w-100"><span className="label-text">{cardType}</span>
                                        <Textarea id="form-add-card-text" name="text"
                                                  value={text} onChange={onChangeText}
                                                  className="form-control" rows="4"
                                                  placeholder=""/>
                                    </label>
                                <div className="sub-text float-right">
                                    <span id="card-text-char-count">0</span> words.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm black-btn button-text" disabled={loading} >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            <button type="button" className="btn btn-sm btn-outline-danger button-text"
                                    onClick={clearText}>Clear
                            </button>
                            <button type="button" className="btn btn-sm btn-outline-secondary button-text" data-bs-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}