import React from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";

export default function CardSuggestion(props) {

    const showEditSuggestionModal = () => {
        window.setSuggestionModalFormFields(props);
        document.getElementById('add-suggestion-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit your suggestion';

    };

    const rateSuggestion = (direction) => {

        var rating_data = {"card_suggestion_id": props.data.id, "up_or_down": direction};
        var api_endpoint = "card_suggestions/rate";

        axios.post(APIService.API_URL + api_endpoint, rating_data).then((response) => {

            if (response.data.errors) {

            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">Your rating has been saved.</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    return (

        <li className="list-group-item border-0 flex-column align-items-start p-3 mb-3">
            <div className="checklist-item checklist-item-primary">

                <div className="d-block text-right icon-link">
                    {props.data.user_token === window.CurrentUserToken &&

                    <a href="#" onClick={showEditSuggestionModal} title="Edit"
                       data-bs-toggle="modal" data-bs-target="#add-suggestion-modal"
                       data-bs-dismiss="modal">
                        <span className="icon-text">
                            <i className="material-icons-outlined icon">settings</i> EDIT
                        </span>
                    </a>}
                    <div className="d-none">
                        <a href="#" onClick={() => rateSuggestion('up')}> Rate Up </a>
                    </div>
                    <div className="d-none">
                        <a href="#" onClick={() => rateSuggestion('down')}> Rate Down </a>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <div>
                        {props.data.description}
                    </div>

                </div>
            </div>
            <hr className="horizontal dark mt-4 mb-2"/>
        </li>

    );
}