import React from "react";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";
import Profile from "./Profile";

const Account = () => {

    return (
        <div className="row mb-5">
            <div className="col-lg-3 ms-auto">
                <div className="card position-sticky top-1">
                    <ul className="nav flex-column bg-white border-radius-lg p-3">
                        <li className="nav-item mb-2">
                            <a className="d-flex" data-scroll="" href="#view-profile">
                                <i className="material-icons-round text-lg me-2">person</i>
                                <span className="side-nav-link text-sm">Profile</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2 mb-2">
                            <a className="d-flex" data-scroll="" href="#update-profile">
                                <i className="material-icons-round text-lg me-2">manage_accounts</i>
                                <span className="side-nav-link text-sm">Update profile</span>
                            </a>
                        </li>
                        <li className="nav-item pt-2 mb-2">
                            <a className="d-flex" data-scroll="" href="#update-password">
                                <i className="material-icons-round text-lg me-2">lock</i>
                                <span className="side-nav-link text-sm">Change password</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
                <Profile />
                <UpdateProfile />
                <UpdatePassword />
            </div>
        </div>
    );
};

export default Account;
