import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 8 || value.length > 40) {
        return (
            <div className="text-danger" role="alert">
                The password must be between 8 and 40 characters.
            </div>
        );
    }
};

const vconfirmpassword = (value) => {
    var pw = document.getElementById('password').value
    if (value !== pw) {
        return (
            <div className="text-danger" role="alert">
                The passwords should match.
            </div>
        );
    }
};


const ResetPassword = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [showform, setShowform] = useState(true);

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleResetPassword = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);
        setShowform(true);

        form.current.validateAll();

        var url = new URL(window.location);
        var reset_password_token = url.searchParams.get("reset_password_token");

        if (checkBtn.current.context._errors.length === 0) {

            axios.put(APIService.AUTH_URL + "password", {
                'user': {
                    password,
                    password_confirmation: confirmPassword,
                    reset_password_token: reset_password_token
                }
            }).then((response) => {

                if (response.data.errors) {

                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                    setShowform(true);
                } else {
                    setLoading(false);
                    setSuccessful(true);
                    setShowform(false);
                    setMessage("");
                    setPasswordShown(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        html: '<span style="font-weight: 300;">Your password has been reset. Please sign in.</span>',
                        color: '#000000',
                        customClass: {
                            confirmButton: 'btn black-btn button-text m-1',
                            title: 'swal-text'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
                setShowform(true);
            });

        } else {
            setLoading(false);
        }
    };

    return (
        <section>
            <div className="page-header ">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column m-auto">
                            <div className="card card-plain">
                                <div className="card-header text-center mb-2">
                                    <h5 className="font-weight-bolder">Reset password</h5>
                                </div>
                                <div className="card-body mt-2">

                                    <Form onSubmit={handleResetPassword} ref={form}>

                                        {message && (
                                            <div className="form-group">
                                                <div className="text-primary mb-3" role="alert">
                                                    <div dangerouslySetInnerHTML={{__html: message}}/>
                                                </div>
                                            </div>
                                        )}
                                        {showform && (<div>
                                            <div className="mb-3 position-relative">
                                                <label className="form-label w-100"><span className="label-text">New password</span>
                                                    <Input type={passwordShown ? "text" : "password"}
                                                           className="form-control"
                                                           placeholder="Password"
                                                           name="password" id="password"
                                                           value={password}
                                                           onChange={onChangePassword}
                                                           validations={[required, vpassword]}
                                                    />
                                                    <i className="material-icons-round show-password"
                                                       onClick={togglePassword}>visibility</i>
                                                </label>
                                            </div>
                                            <div className="mb-3 position-relative">
                                                <label className="form-label w-100"><span className="label-text">Confirm password</span>
                                                    <Input type={passwordShown ? "text" : "password"}
                                                           className="form-control"
                                                           placeholder="Confirm password"
                                                           name="confirmPassword"
                                                           value={confirmPassword}
                                                           onChange={onChangeConfirmPassword}
                                                           validations={[required, vconfirmpassword]}
                                                    />
                                                    <i className="material-icons-round show-password"
                                                       onClick={togglePassword}>visibility</i>
                                                </label>
                                            </div>

                                            <div className="text-center">
                                                <button className="btn btn-sm black-btn button-text"
                                                        disabled={loading}>
                                                    {loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    <span> Submit</span>
                                                </button>
                                            </div>
                                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                                        </div>)}
                                    </Form>
                                </div>
                                <div className="card-footer text-center pt-4 mt-2">
                                    <p>Not yet registered? <a href="/register"
                                                              className="font-weight-bold">Sign
                                        up</a></p>
                                    <p><a href="/login" className="font-weight-bold">Sign in</a></p>
                                    <p><a href="/forgot-password" className="font-weight-bold">Resend password reset
                                        email</a></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default ResetPassword;
