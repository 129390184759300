import React from 'react';
import Navbar from "../Dashboard/Navbar";
import Footer from "../Dashboard/Footer";

export default function Home() {

    return (
        <div className="wrapper home">
            <Navbar showTourLink="false" auth_mode="unauth" activeMenu="home" />
            <main className="main">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 me-3 ms-auto wow fadeInLeft" data-wow-delay=".4s" data-wow-duration="2s">
                            <div className="brand-name">NARRA</div>
                            <div className="brand-tagline">
                                <p>Write and share elegant explainers of products, services or ideas.</p>
                                <p>Built by writers for writers.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-7 d-flex flex-column m-auto">

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p><span className="label">CONTEXT</span></p>

                            <p>Great writing is an art.</p>

                            <p>It is more than merely putting words together.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">THE PROBLEM</span></p>

                            <p>But often great writing takes a back seat.</p>

                            <p>The visuals and audio become the main attraction.</p>

                            <p>The copy is simply an ingredient.</p>

                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">THE SOLUTION</span></p>

                            <p>NARRA changes this for copywriters.</p>

                            <p>It is a simple, interactive platform designed to help you write and share your art.</p>

                            <p>Designed by a passionate and expert copywriter.</p>

                            <p>Based on years of experience writing explainers for individuals, startups and established
                                organisations.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">FEATURES</span></p>

                            <p>You get simple, no-fluff templates as guides that you can follow or customise.</p>

                            <p>Based on the most effective storylines for explainers.</p>

                            <p>Invite collaborators, via email or a public link, to offer suggestions.</p>

                            <p>Publish your completed narrative on NARRA and get an elegant page like this.</p>

                            <p>Compile and share a stylish portfolio of your work.</p>
                            </div>

                            <div className="wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
                            <p className="mt-4"><span className="label">CALL TO ACTION</span></p>

                            <p>Your writing is too good to be a side dish.</p>

                            <p>Give it the shine it deserves.</p>

                            <p>Showcase its beauty with NARRA.</p>

                            </div>

                        </div>
                    </div>
                </div>
                <Footer mode={'home'}/>
            </main>
        </div>
    );
}

