import React from "react";

export default function UserContact(props) {

    const showEditContactModal = () => {
        window.setContactModalFormFields(props);
        document.getElementById('add-contact-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit contact';
    };

    return (

        <tr>
            <td className="align-middle">{props.data.email}</td>
            <td className="align-middle">{props.data.name}</td>
            <td className="align-middle text-center text-sm">
                {props.data.num_boards}
            </td>
            <td className="align-middle text-end text-sm">
                <button type="button" rel="tooltip" data-bs-toggle="modal" data-bs-target="#add-contact-modal"
                        onClick={showEditContactModal} title="Edit"
                        className='btn btn-sm button-text'>
                    <i className="material-icons-outlined icon">settings</i> Edit
                </button>
            </td>
        </tr>



    );
}