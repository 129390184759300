import React, {useState} from "react";
import APIService from "../../services/api.service";
import Joyride, {ACTIONS, STATUS} from "react-joyride";

export default function QuickGuide() {

    var steps = [
        {
            target: 'body',
            content: <span><h5>Welcome onboard</h5>
                 <p>Congratulations on making it this far!</p>
                <img src="/dashboard/images/undraw_Landing_page_re_6xev.svg" className="w-70"/>
                <p>Before you begin, how about a quick tour?</p>
               </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: '.add-button-dropdown',
            content: <span><h5>Add an activity or a list</h5>
                <img src="/dashboard/images/undraw_Add_tasks_re_s5yj.svg" className="w-50"/>
               </span>,
        },
        {
            target: '.current-reminders',
            content: <span><h5>Reminders</h5>
                <p>View and and complete your reminders.</p>
                <img src="/dashboard/images/undraw_Plans_re_s9mf.svg" className="w-50"/>
               </span>,
        },
        {
            target: 'body',
            content: <span><h5>Lists</h5>
                <p>Use lists to organise your activities.</p>
                <img src="/dashboard/images/undraw_task_list_6x9d.svg" className="w-50"/>
                </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: '.card.awards',
            content: <span><h5>Status and Progress</h5>
                <p>See how your activities are doing with status and stats.</p>
                <img src="/dashboard/images/undraw_all_the_data_re_hh4w.svg" className="w-50"/>
               </span>,
        },
        {
            target: 'body',
            content: <span><h5>Contacts</h5>
                <p>Manage contact details of your clients.</p>
                <img src="/dashboard/images/undraw_team_re_0bfe.svg" className="w-50"/>
                </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
        {
            target: 'body',
            content: <span><h5>That's it for now</h5>
                <p>Get in touch at anytime if you need any help. We'd love to assist.</p>
                <img src="/dashboard/images/undraw_creative_team_r90h.svg" className="w-70"/>
               </span>,
            placement: 'center',
            styles: {
                options: {
                    width: 550,
                }
            }
        },
    ];
    var showTour = false;

    var tourStatus = APIService.getTourStatus();
    var currentUser = APIService.getCurrentUser();
    if (tourStatus !== 'true' && currentUser && currentUser.user.sign_in_count < 50) {
        // TODO UNCOMMENT THIS TO SHOW THE TOUR WHEN USERS FIRST SIGN UP
        // showTour = true;
    }

    const [runTour, setRunTour] = useState(showTour);

    function startTour() {
        setRunTour(true);
    }

    window.startTour = startTour;

    const handleJoyrideCallback = (dt) => {
        const {status, action} = dt;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const finishedActions = [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP];

        if (finishedStatuses.includes(status) || finishedActions.includes(action)) {
            setRunTour(false);
            APIService.setTourStatus();
        }
    };

    return (
        <div>

            <Joyride run={runTour}
                     callback={handleJoyrideCallback}
                     scrollToFirstStep={true}
                     steps={steps}
                     continuous={true}
                     showProgress={true}
                     showSkipButton={true}
                     disableOverlayClose={true}
                     locale={{skip: <span className="text-secondary">Skip tour</span>, last: 'Done'}}
                     styles={{
                         options: {
                             primaryColor: '#E91E63',
                             width: 400,
                             zIndex: 14000
                         }
                     }}/>
        </div>
    )
}