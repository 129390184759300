import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import CardSuggestion from "./CardSuggestion";
import Swal from "sweetalert2";

export default function CardSuggestions(props) {

    const activeCardID = props.card_id;
    const [suggestionsData, updateSuggestionsData] = useState();
    const [loading, setLoading] = useState(true);

    window.activeCardID = activeCardID
    window.updateCardSuggestionData = updateSuggestionsData;

    var api_endpoint = "card_suggestions/get_by_card_id_public";
    var headersData = {};
    if (props.auth_mode == "auth") {
        api_endpoint = "card_suggestions/get_by_card_id";
        headersData =  {"Authorization": APIService.getHeaders().authorization};
    }

    var paramsData =  {"board_card_id": activeCardID};
    var url = new URL(window.location);
    var invitation_token = url.searchParams.get("invitation_token");
    if(invitation_token != ""){
        paramsData =  {"board_card_id": activeCardID, "invitation_token": invitation_token };
    }

    useEffect(() => {
        axios.get(APIService.API_URL + api_endpoint , { params: paramsData,
            headers: headersData
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateSuggestionsData(response.data.card_suggestions);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, [props]);

    const showAddSuggestionModal = () => {

        if(props.auth_mode != "auth"){
             Swal.fire({
                icon: 'info',
                title: 'Almost there...',
                html: '<span style="font-weight: 300;">You need to sign in to add suggestions.</span>',
                showCancelButton: true,
                confirmButtonText: 'Sign in',
                 color: '#000000',
                 customClass: {
                     confirmButton: 'btn black-btn button-text m-1',
                     title: 'swal-text',
                    cancelButton: 'btn btn-outline-secondary button-text m-1'
                },
                buttonsStyling: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.assign("/login");
                }
            });

            document.getElementById('add-suggestion-modal-close').click();

        } else {
            window.setSuggestionModalFormFields({data: {board_card_id: activeCardID, description: "", id: ""}});
            document.getElementById('add-suggestion-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a suggestion';
        }
    };

    if (loading) {
        return <div/>;
    }

    return (
        <div className="row mt-lg-4 mt-2 mb-4">

            <div className="ms-auto d-none d-md-block">
                <button className="btn btn-sm black-btn m-3 ms-0 button-text" data-bs-toggle="modal"
                        data-bs-target="#add-suggestion-modal" data-bs-dismiss="modal" onClick={showAddSuggestionModal}>Add a suggestion
                </button>
            </div>
            {suggestionsData.length > 0 && <ul className="list-group list-group-flush">

                {suggestionsData.map((c, index) => (
                    <CardSuggestion
                        data={c}
                        key={index}
                        index={index}
                    />
                ))}

            </ul>}
            {suggestionsData.length < 1 &&
            <p>This section has no suggestions yet.</p>
            }

        </div>
    );
};

