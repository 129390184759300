import React from "react";

export default function Navbar(props) {

    function showAddBoardModal() {
        document.getElementById('add-board-modal').getElementsByClassName("modal-title")[0].innerText = 'Create a narrative';
    }

    function startTour() {
        window.startTour();
    }

    var accountActive = "";
    var tourActive = "";
    var logoutActive = "";
    var boardsActive = "";
    var portfolioActive = "";

    switch (props.activeMenu) {
        case 'boards':
            boardsActive = "active";
            break;
        case 'portfolio':
            portfolioActive = "active";
            break;
        case 'tour':
            tourActive = "active";
            break;
        case 'account':
            accountActive = "active";
            break;
        case 'logout':
            logoutActive = "active";
            break;
        default:
        // code block
    }


    return (
        <div>
        {props.activeMenu === 'preview' && <nav className="navbar message pt-0 pb-0 text-center" id="navbarBlur">
            <p className="w-100 mb-0">This is how your page will appear when published.</p></nav>}
        {props.activeMenu !== 'none' && props.activeMenu !== 'preview' && <nav className="navbar navbar-expand-lg" id="navbarBlur">
            <div className="container-xl">
                <div className="navbar-brand">
                    <a href="/">
                        Narra
                    </a>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fa fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {props.auth_mode === "auth" &&
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item px-3">
                            <a className={'nav-link ' + boardsActive} aria-current="page" href="/narratives">
                                <i className="material-icons-round icon opacity-10">history_edu</i> &nbsp;
                                <span className="nav-text">Narratives</span>
                            </a>
                        </li>
                        <li className="nav-item px-3">
                            <a className={'nav-link ' + portfolioActive} href="/portfolio/set"> <i
                                className="material-icons-round icon opacity-10">group</i> &nbsp;Portfolio</a>
                        </li>
                        <li className="nav-item px-3 d-none">
                            <a className={'nav-link ' + tourActive} href="#" onClick={startTour}> <i
                                className="material-icons-round icon opacity-10">tour</i> &nbsp;Quick Guide</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className={'nav-link ' + accountActive} href="/account"> <i
                                className="material-icons-round icon opacity-10">account_circle</i> &nbsp;Account</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className={'nav-link ' + logoutActive} href="/logout"> <i
                                className="material-icons-round icon opacity-10">logout</i> &nbsp;Logout</a>
                        </li>
                    </ul>}
                    {props.auth_mode === "unauth" &&
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item px-3">
                            <a className='nav-link' href="/how"> &nbsp;How it Works</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className='nav-link' href="/philosophy"> &nbsp;The Philosophy</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className='nav-link' href="/register"> &nbsp;Sign up</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className='nav-link' href="/login"> &nbsp;Sign in</a>
                        </li>
                    </ul>}
                    <div className="d-flex">
                        {props.auth_mode === "auth" &&
                        <button data-bs-toggle="modal" data-bs-target="#add-board-modal" onClick={showAddBoardModal}
                                className="btn btn-sm black-btn button-text"
                                type="button"><i className="material-icons-round icon opacity-10">history_edu</i> &nbsp;Create narrative</button>}
                    </div>
                </div>
            </div>
        </nav>}
        </div>

    )
}