import React  from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import Philosophy from "../Home/Philosophy";
import HowItWorks from "../Home/HowItWorks";

function App() {

    return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/philosophy">
                        <Philosophy />
                    </Route>
                    <Route exact path="/how">
                        <HowItWorks />
                    </Route>
                    <Route exact path="/login">
                        <Dashboard />
                    </Route>
                    <Route exact path="/register">
                        <Dashboard />
                    </Route>
                    <Route exact path="/logout">
                        <Dashboard />
                    </Route>
                    <Route exact path="/resend-confirmation">
                        <Dashboard />
                    </Route>
                    <Route exact path="/confirmation">
                        <Dashboard />
                    </Route>
                    <Route exact path="/forgot-password">
                        <Dashboard />
                    </Route>
                    <Route exact path="/password/edit">
                        <Dashboard />
                    </Route>
                    <Route exact path="/resend-unlock">
                        <Dashboard />
                    </Route>
                    <Route exact path="/unlock">
                        <Dashboard />
                    </Route>
                    <Route exact path="/account">
                        <Dashboard />
                    </Route>
                    <Route path="/narratives">
                        <Dashboard />
                    </Route>
                    <Route path="/craft/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/preview/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/publish/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/pdf/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/portfolio/set">
                        <Dashboard />
                    </Route>
                    <Route path="/portfolio/preview">
                        <Dashboard />
                    </Route>
                    <Route path="/portfolio/view/:id">
                        <Dashboard />
                    </Route>
                    <Route path="/contributors">
                        <Dashboard />
                    </Route>
                    <Route exact path="/privacy">
                        <Dashboard />
                    </Route>
                    <Route exact path="/terms">
                        <Dashboard />
                    </Route>
                </Switch>
            </BrowserRouter>
    );
}

export default App;