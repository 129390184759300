import React from 'react';
import Navbar from "../Dashboard/Navbar";
import Footer from "../Dashboard/Footer";

export default function Home() {

    return (
        <div className="wrapper home how other">
            <Navbar showTourLink="false" auth_mode="unauth" activeMenu="home"/>
            <main className="main">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 d-flex flex-column m-auto text-center">
                            <div className="wow fadeInLeft mb-5" data-wow-duration="2s" data-wow-delay=".2s">
                                <div className="brand-name">HOW IT WORKS</div>
                                <div className="brand-tagline">
                                    <p>Write and deliver faster.</p>
                                    <p>Present scripts elegantly.</p>
                                    <p>Showcase your portfolio.</p>
                                </div>
                            </div>

                            <div className="wow fadeInRight mb-5" data-wow-duration="2s" data-wow-delay=".2s">
                                <p className="mt-4"><span className="label">CREATE & EDIT NARRATIVES</span></p>

                                <img src="/assets/images/create.gif" width="100%" height="auto"
                                     alt="Create narratives"/>

                            </div>

                            <div className="wow fadeInRight mb-5" data-wow-duration="2s" data-wow-delay=".2s">
                                <p className="mt-4"><span className="label">INVITE COLLABORATORS</span></p>

                                <img src="/assets/images/collaborate.gif" alt="Invite collaborators" width="100%"
                                     height="auto"/>


                            </div>

                            <div className="wow fadeInRight mb-5" data-wow-duration="2s" data-wow-delay=".2s">
                                <p className="mt-4"><span className="label">PUBLISH NARRATIVE PAGES</span></p>

                                <img src="/assets/images/publish.gif" alt="Publish narratives" width="100%"
                                     height="auto"/>

                            </div>

                            <div className="wow fadeInRight mb-5" data-wow-duration="2s" data-wow-delay=".2s">
                                <p className="mt-4"><span className="label">SHOWCASE YOUR PORTFOLIO</span></p>

                                <img src="/assets/images/portfolio.gif" alt="Showcase your portfolio" width="100%"
                                     height="auto"/>

                            </div>


                        </div>
                    </div>
                </div>
                <Footer mode={'home'}/>
            </main>
        </div>
    );
}

