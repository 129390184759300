import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Select from "react-validation/build/select";
import Swal from 'sweetalert2';
import {WithContext as ReactTags} from 'react-tag-input';
import {isEmail} from "validator";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function AddBoardModal() {

    window.addEventListener('load', function load() {

        [...document.getElementsByClassName("ReactTags__tagInputField")].forEach((el) => {
            el.parentElement.parentElement.classList.add("form-control");
            el.parentElement.parentElement.classList.add("d-flex");
            el.parentElement.parentElement.classList.add("flex-wrap");

        });

        if (!document.getElementById("add-contact-tag-btn")) {
            var contactsInput = document.getElementById('form-add-item-emails');
            const asnBt = document.createElement('button');
            asnBt.type = "button";
            asnBt.setAttribute("id", "add-contact-tag-btn");
            asnBt.classList.add("ReactTags__add");
            asnBt.innerHTML = '+ Add';
            contactsInput.parentNode.appendChild(asnBt);

            asnBt.onclick = function (e) {
                var val = e.target.parentElement.getElementsByTagName('input')[0].value.trim();
                var assn = {id: val, text: val};
                e.target.parentElement.getElementsByTagName('input')[0].value = '';
                window.handleContactsAddition(assn);
            };
        }


    });

    const form = useRef();
    const checkBtn = useRef();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [publicBoard, setPublicBoard] = useState(false);
    const [boardToken, setBoardToken] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    var initialContact = [];
    var boardTemplates = [];
    var currUser = APIService.getCurrentUser();
    if (currUser) {
        initialContact = [{
            id: currUser.user.email,
            text: currUser.user.email,
        }];

        boardTemplates = currUser.user.board_templates;

    }

    const [contacts, updateContacts] = useState(initialContact);
    const [template, setTemplate] = useState(1);
    const [templateDisabled, setTemplateDisabled] = useState(false);

    var contactsSuggestions = [];
    var contactsObj = APIService.getContacts();
    if (contactsObj) {
        for (var ii = 0; ii < contactsObj.length; ii++) {
            contactsSuggestions = [...contactsSuggestions, {
                id: contactsObj[ii].email,
                text: contactsObj[ii].email
            }];
        }
    }

    function handleContactsDelete(i) {
        updateContacts(contacts.filter((contact, index) => index !== i));
    }

    function handleContactsAddition(contact) {
        if (!isEmail(contact.text)) {
            document.getElementById("contacts-msg").classList.remove("hidden");
            document.getElementById('form-add-item-emails').value = contact.text;
            return;
        }
        document.getElementById("contacts-msg").classList.add("hidden");

        updateContacts([...contacts, contact]);
    }

    window.handleContactsAddition = handleContactsAddition;

    function handleContactsDrag(contact, currPos, newPos) {
        const newContacts = contacts.slice();
        newContacts.splice(currPos, 1);
        newContacts.splice(newPos, 0, contact);
        updateContacts(newContacts);
    }

    const KeyCodes = {
        comma: 188,
        enter: 13,
        space: 32
    };

    const delimiters = [KeyCodes.enter, KeyCodes.comma, KeyCodes.space];


    const descrMaxChars = 200;
    const titleMaxChars = 75;

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
        document.getElementById("board-title-char-count").innerText = e.target.value.length;
    };

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("board-description-char-count").innerText = e.target.value.length;
    };

    const onChangeTemplate = (e) => {
        setTemplate(e.target.value);
    };

    const onChangePublicBoard = (e) => {
        setPublicBoard(e.target.checked);
    };

    const onChangeBoardToken = (e) => {
        setBoardToken(e.target.value);
    };

    const onClickModalClose = () => {

        document.getElementById("board-title-char-count").innerText = '0';
        document.getElementById("board-description-char-count").innerText = '0';

        setTitle("");
        setDescription("");
        setPublicBoard(false);
        setBoardToken("");
        setTemplate(1);
        setTemplateDisabled(false);

        if (currUser) {
            updateContacts([{
                id: currUser.user.email,
                text: currUser.user.email,
            }]);

        } else {
            updateContacts([]);
        }

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickBoardDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            html: '<span style="font-weight: 300;">Your narrative will be permanently deleted.</span>',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            color: '#000000',
            customClass: {
                confirmButton: 'btn btn-outline-danger button-text m-1',
                title: 'swal-text',
                cancelButton: 'btn btn-outline-secondary button-text m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteBoard(boardToken);
            }
        });
    }

    const setBoardModalFormFields = (data) => {

        setBoardToken(data.data.user_board.token);
        setTitle(data.data.user_board.title);
        setDescription(data.data.user_board.description);
        setPublicBoard(data.data.user_board.public);
        setTemplate(data.data.user_board.board_template_id);
        setTemplateDisabled(true);

        var boardContacts = [];
        if (data.data.board_contacts) {
            for (var ik = 0; ik < data.data.board_contacts.length; ik++) {
                boardContacts = [...boardContacts, {
                    id: data.data.board_contacts[ik].user_contact.email,
                    text: data.data.board_contacts[ik].user_contact.email
                }];
            }
        }
        updateContacts(boardContacts);

        document.getElementById("board-title-char-count").innerText = data.data.user_board.title.length;
        document.getElementById("board-description-char-count").innerText = data.data.user_board.description.length;

    }

    window.setBoardModalFormFields = setBoardModalFormFields;

    const addNewBoard = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        var contactsArray = contacts;
        if (contacts.length === 0) {
            contactsArray = [{
                id: currUser.user.email,
                text: currUser.user.email,
            }];
        }

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "user_boards/add";
            var board_data = {
                "user_board_token": boardToken, "user_board": {
                    "title": title, "description": description, "public": publicBoard,
                    "board_template_id": template, "contacts": contactsArray
                }
            };

            if (boardToken !== "") {
                api_endpoint = "user_boards/update";
            }

            axios.post(APIService.API_URL + api_endpoint, board_data,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    if (!boardToken) {
                        sessionStorage.removeItem("user_boards");
                        window.location.assign("/craft/" + response.data.user_board_token);
                    } else {
                        APIService.resetBoards();
                        APIService.resetBoardCards();
                        document.getElementById('add-board-modal-close').click();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            html: '<span style="font-weight: 300;">Your narrative has been saved.</span>',
                            color: '#000000',
                            customClass: {
                                confirmButton: 'btn black-btn button-text m-1',
                                title: 'swal-text'
                            },
                            buttonsStyling: false
                        });
                    }
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal fade" tabIndex="-1" role="dialog" id="add-board-modal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewBoard} className="navbar-form" id="form-add-board-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Create a narrative</h5>
                            <button id="add-board-modal-close" className="close-btn btn btn-icon" type="button"
                                    data-bs-dismiss="modal" onClick={onClickModalClose}>
                                <i className="material-icons-round">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="board-token" value={boardToken} onChange={onChangeBoardToken}/>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label w-100"><span
                                    className="label-text">Name</span>
                                    <Input type="text" id="form-add-board-title" name="title"
                                           className="form-control mt-1"
                                           value={title} maxLength={titleMaxChars} placeholder="Name here..."
                                           onChange={onChangeTitle}
                                           validations={[required]}/>
                                </label>

                                <div className="sub-text float-right">
                                    <span id="board-title-char-count">0</span> / {titleMaxChars} chars.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label w-100"><span
                                    className="label-text">Short description</span>
                                    <Textarea id="form-add-board-description" name="description"
                                              value={description} maxLength={descrMaxChars}
                                              className="form-control mt-1" onChange={onChangeDescription}
                                              placeholder="Short description (optional)..." rows="3"
                                    />
                                </label>
                                <div className="sub-text float-right">
                                    <span id="board-description-char-count">0</span> / {descrMaxChars} chars.
                                </div>
                            </div>
                            <hr className="horizontal dark"></hr>
                            <div className="mb-3 mt-5">
                                <label htmlFor="contacts" className="form-label w-100">
                                    <span className="label-text">Pick a template</span>
                                    <Select id="template" className="form-control"
                                            name="template" disabled={templateDisabled}
                                            value={template} onChange={onChangeTemplate}
                                            validations={[required]}>
                                        {boardTemplates && boardTemplates.map((c, index, prop) => (
                                            <option key={c.id} value={c.id}>{c.title}</option>
                                        ))}
                                    </Select>
                                </label>
                            </div>
                            <hr className="horizontal dark"></hr>
                            <div className="mb-3 mt-5">
                                <label htmlFor="contacts" className="form-label w-100"><span
                                    className="label-text">Invite contributors</span>
                                    <ReactTags id="form-add-item-emails"
                                               tags={contacts}
                                               suggestions={contactsSuggestions}
                                               handleDelete={handleContactsDelete}
                                               handleAddition={handleContactsAddition}
                                               handleDrag={handleContactsDrag}
                                               delimiters={delimiters}
                                               placeholder="Type and click add"
                                               inline={true}
                                               allowUnique={true}
                                               minQueryLength={3}
                                    />
                                </label>
                                <div id="contacts-msg" className="text-primary hidden text-sm">Enter valid email
                                    addresses.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contacts" className="form-label w-100"><span
                                    className="label-text">Allow public contributors</span>
                                    <label className="switch">
                                        <input type="checkbox" onChange={onChangePublicBoard} checked={publicBoard}/>
                                        <span className="slider round"></span>
                                    </label>
                                </label>
                                <div className="sub-text float-right">
                                    Anyone with a link to the narrative can add suggestions.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm black-btn button-text" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {boardToken && <button type="button" className="btn btn-sm btn-outline-danger button-text"
                                                   data-bs-dismiss="modal"
                                                   onClick={onClickBoardDelete}>Delete
                            </button>}

                            <button type="button" className="btn btn-sm btn-outline-secondary button-text"
                                    data-bs-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}