import React from "react";

export default function Footer(props) {

    return (
        <footer className="footer mt-5 pt-3">
            <hr className="horizontal dark"></hr>
            <div className="container-xl text-center text-sm text-secondary pt-3">
                {props.mode === 'home' && <p>&copy; <a href="/">NARRA</a> &nbsp; | &nbsp; <a href="/terms">T&C</a> &nbsp; | &nbsp; <a href="/privacy">Privacy</a></p>}
                {props.mode === 'publ' && <p>Write and share elegant explainers with <a target="_blank" href="/">NARRA</a>.</p>}
            </div>
        </footer>
    )
}