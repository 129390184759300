import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};


const vpassword = (value) => {
    if (value.length < 8 || value.length > 40) {
        return (
            <div className="text-primary" role="alert">
                The password must be between 8 and 40 characters.
            </div>
        );
    }
};

const vconfirmpassword = (value) => {
    var pw = document.getElementById('newpassword').value;
    if (value !== pw) {
        return (
            <div className="text-primary" role="alert">
                The passwords should match.
            </div>
        );
    }
};

const UpdatePassword = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [currpassword, setCurrpassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeCurrpassword = (e) => {
        const currpassword = e.target.value;
        setCurrpassword(currpassword);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleUpdateAccount = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            const user = {
                'user': {
                    password,
                    password_confirmation: confirmPassword,
                    current_password: currpassword
                }
            };

            axios.put(APIService.AUTH_URL + "signup", user,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    sessionStorage.setItem("user", JSON.stringify(response.data));
                    setLoading(false);
                    setSuccessful(true);
                    setCurrpassword("");
                    setPassword("");
                    setConfirmPassword("");
                    setMessage("");
                    setPasswordShown(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        html: '<span style="font-weight: 300;">Your password has been updated.</span>',
                        color: '#000000',
                        customClass: {
                            confirmButton: 'btn black-btn button-text m-1',
                            title: 'swal-text'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });

        } else {
            setLoading(false);
        }
    };

    return (
        <div className="card mt-4" id="update-password">
                    <div className="card-header mb-3">
                        <h5>Change password</h5>
                    </div>
                    <div className="card-body pt-0">
                        <Form onSubmit={handleUpdateAccount} ref={form} >

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <div className="mb-3 position-relative">
                                <label className="form-label w-100"><span className="label-text">Current password</span>
                                <Input type={passwordShown ? "text" : "password"}
                                       className="form-control" placeholder="Current password"
                                       name="currpassword"
                                       value={currpassword}
                                       onChange={onChangeCurrpassword}
                                       validations={[required]}
                                />
                                    <i className="material-icons-round show-password" onClick={togglePassword}>visibility</i>
                                </label>
                            </div>
                            <div className="mb-3 position-relative">
                                <label className="form-label w-100"><span className="label-text">New password</span>
                                <Input type={passwordShown ? "text" : "password"}
                                       className="form-control" placeholder="New password"
                                       name="password" id="newpassword"
                                       value={password}
                                       onChange={onChangePassword}
                                       validations={[required, vpassword]}
                                />
                                    <i className="material-icons-round show-password" onClick={togglePassword}>visibility</i>
                                </label>
                            </div>
                            <div className="mb-3 position-relative">
                                <label className="form-label w-100"><span className="label-text">Confirm new password</span>
                                <Input type={passwordShown ? "text" : "password"}
                                       className="form-control" placeholder="Confirm new password"
                                       name="confirmPassword"
                                       value={confirmPassword}
                                       onChange={onChangeConfirmPassword}
                                       validations={[required, vconfirmpassword]}
                                />
                                    <i className="material-icons-round show-password" onClick={togglePassword}>visibility</i>
                                </label>
                            </div>

                            <div className="input-group input-group-outline">

                                <button className="btn btn-sm black-btn mt-2 mb-0 button-text" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span> Save </span>
                                </button>
                            </div>
                            <CheckButton style={{display: "none"}} ref={checkBtn}/>

                        </Form>

                        <p className="text-bold mb-2 mt-5">
                            Please follow this guide for a strong password:
                        </p>
                        <ul className="text-muted ps-4 mb-0 float-start">
                            <li>
                                <span className="text-sm">Min 8 characters (required)</span>
                            </li>
                            <li>
                                <span className="text-sm">Include special characters (recommended)</span>
                            </li>
                            <li>
                                <span className="text-sm">Include numbers (recommended)</span>
                            </li>
                            <li>
                                <span className="text-sm">Change it often (recommended)</span>
                            </li>
                        </ul>
                    </div>
                </div>
    );
};

export default UpdatePassword;
