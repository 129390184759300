import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Card from "./Card";
import BoardComments from "./BoardComments";
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";

export default function Board(props) {

    const activeBoardToken = props.token;
    const [boardData, updateBoardData] = useState();
    const [publishedStatus, updatePublishedStatus] = useState();
    const [portfolioStatus, updatePortfolioStatus] = useState();
    const [loading, setLoading] = useState(true);

    window.activeBoardToken = activeBoardToken;
    window.updateBoardData = updateBoardData;

    var api_endpoint = "user_boards/get_by_invitation_token";
    var headersData = {};
    if (props.auth_mode === "auth") {
        api_endpoint = "user_boards/get_by_token";
        headersData = {"Authorization": APIService.getHeaders().authorization};
    } else if (props.auth_mode === "publ") {
        api_endpoint = "user_boards/view";
    }

    var paramsData = {"token": props.token};
    var url = new URL(window.location);
    var invitation_token = url.searchParams.get("invitation_token");
    if (invitation_token) {
        paramsData = {"token": props.token, "invitation_token": invitation_token};
    }

    useEffect(() => {
        axios.get(APIService.API_URL + api_endpoint, {
            params: paramsData,
            headers: headersData
        }).then((response) => {

            if (response.data.errors) {

                Swal.fire({
                    icon: 'info',
                    title: 'Oops',
                    html: '<span style="font-weight: 300;">' + response.data.errors.message[0] + '</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    window.location.assign("/");
                });

            } else {
                updateBoardData(response.data.user_board);
                updatePublishedStatus(response.data.user_board.user_board.published);
                updatePortfolioStatus(response.data.user_board.user_board.portfolio);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, [props]);

    const showEditBoardModal = () => {
        window.setBoardModalFormFields({data: boardData});
        document.getElementById('add-board-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit your narrative';
    };

    const showPreview = () => {
        window.open("/preview/" + activeBoardToken);
    };

    const showPage = () => {
        window.open("/publish/" + activeBoardToken);
    };

    const exportPDF = () => {
        window.open("/pdf/" + activeBoardToken);
    };

    const publishBoard = () => {

        var board_data = {"user_board_token": activeBoardToken, publish: !publishedStatus};
        var api_endpoint = "user_boards/publish";

        axios.post(APIService.API_URL + api_endpoint, board_data,
            {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {

            } else {
                updatePublishedStatus(response.data.user_board.published);
                var pubSt = 'published';
                var pubMsg = '<p>View your published page <a target="_blank" href="/publish/' + activeBoardToken + '">here</a>.</p>'
                if (!response.data.user_board.published) {
                    pubSt = 'unpublished';
                    pubMsg = '';
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">' +
                        '<p>Your narrative has been ' + pubSt + '.</p>' +
                        pubMsg +
                        '</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    const portfolioBoard = () => {

        var board_data = {"user_board_token": activeBoardToken, portfolio: !portfolioStatus};
        var api_endpoint = "user_boards/add_to_portfolio";

        axios.post(APIService.API_URL + api_endpoint, board_data,
            {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {

            } else {
                updatePortfolioStatus(response.data.user_board.portfolio);
                var pubSt = 'added to';
                if (!response.data.user_board.published) {
                    pubSt = 'removed from';
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">' +
                        '<p>Your narrative has been ' + pubSt + ' your portfolio.</p>' +
                        '</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    const cloneBoard = () => {

        APIService.cloneBoard(activeBoardToken);

    }

    var total_word_count = 0;
    if (boardData && boardData.board_cards) {
        boardData.board_cards.forEach(function (obj) {
            if (obj.text.trim() !== "") {
                total_word_count = total_word_count + obj.text.trim().split(/\s+/).length;
            }
        });
    }


    if (loading) {
        return <div/>;
    }

    return (
        <div className={"board " + props.view_mode}>
            {props.view_mode === 'publish' &&
            <Helmet>
                <title>NARRA | Publish | {boardData.user_board.title}</title>
            </Helmet>
            }
            {props.view_mode === 'craft' && <span>
                <div className="d-flex">
                <div className="my-auto w-lg-50">
                    <h4 className="mb-0 page-title">Craft narrative</h4>
                </div>
                <div className="d-none d-lg-block ms-auto">
                    {boardData.mode === "owner" && <span>
                        <button className="btn btn-sm btn-outline-secondary button-text me-2" data-bs-toggle="modal"
                                data-bs-target="#add-board-modal"
                                onClick={showEditBoardModal}>
                        <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                            EDIT
                        </button>
                        <button className="btn btn-sm btn-outline-secondary button-text me-2"
                                onClick={cloneBoard}>
                        <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                            CLONE
                        </button>
                        <button className="btn btn-sm btn-outline-secondary button-text me-2"
                                onClick={exportPDF}>
                        <i className="material-icons-outlined icon opacity-10">picture_as_pdf</i>&nbsp;
                            PDF
                        </button>
                        {publishedStatus && !portfolioStatus &&
                        <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={portfolioBoard}>
                            <i className="material-icons-outlined icon opacity-10">co_present</i>&nbsp;
                            PORTFOLIO (ADD)
                        </button>}
                        {publishedStatus && portfolioStatus &&
                        <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={portfolioBoard}>
                            <i className="material-icons-outlined icon opacity-10">co_present</i>&nbsp;
                            PORTFOLIO (REMOVE)
                        </button>}
                        {!publishedStatus &&
                        <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={showPreview}>
                            <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                            PREVIEW
                        </button>}
                        {!publishedStatus &&
                        <button className="btn btn-sm yellow-btn button-text" onClick={publishBoard}>
                            <i className="material-icons-outlined icon opacity-10">publish</i>&nbsp;PUBLISH
                        </button>}
                        {publishedStatus &&
                        <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={publishBoard}>
                            <i className="material-icons-outlined icon opacity-10">unpublished</i>&nbsp;UNPUBLISH
                        </button>}
                        {publishedStatus &&
                        <button className="btn btn-sm yellow-btn button-text" onClick={showPage}>
                            <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                            VIEW PAGE
                        </button>}
                    </span>}
                </div>
            </div>
            <div className="d-block d-lg-none mt-3">
                 {boardData.mode === "owner" && <span>
                        <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2"
                                data-bs-toggle="modal"
                                data-bs-target="#add-board-modal"
                                onClick={showEditBoardModal}>
                        <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                            EDIT
                        </button>
                      <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2"
                              onClick={cloneBoard}>
                        <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                          CLONE
                        </button>
                      <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2"
                              onClick={exportPDF}>
                        <i className="material-icons-outlined icon opacity-10">picture_as_pdf</i>&nbsp;
                          PDF
                      </button>
                     {publishedStatus && !portfolioStatus &&
                     <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2" onClick={portfolioBoard}>
                         <i className="material-icons-outlined icon opacity-10">co_present</i>&nbsp;
                         PORTFOLIO (ADD)
                     </button>}
                     {publishedStatus && portfolioStatus &&
                     <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2" onClick={portfolioBoard}>
                         <i className="material-icons-outlined icon opacity-10">co_present</i>&nbsp;
                         PORTFOLIO (REMOVE)
                     </button>}
                     {!publishedStatus &&
                     <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2" onClick={showPreview}>
                         <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                         PREVIEW
                     </button>}
                     {!publishedStatus &&
                     <button className="btn btn-sm yellow-btn button-text mt-2" onClick={publishBoard}>
                         <i className="material-icons-outlined icon opacity-10">publish</i>&nbsp;PUBLISH
                     </button>}
                     {publishedStatus &&
                     <button className="btn btn-sm btn-outline-secondary button-text me-2 mt-2" onClick={publishBoard}>
                         <i className="material-icons-outlined icon opacity-10">unpublished</i>&nbsp;UNPUBLISH
                     </button>}
                     {publishedStatus && <button className="btn btn-sm yellow-btn button-text mt-2" onClick={showPage}>
                         <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                         VIEW PAGE
                     </button>}
                    </span>}
            </div>
            <hr className="horizontal dark"></hr>
            </span>}
            <div className="row mt-5">
                <div className="col-xl-3 col-lg-4 me-3 ms-auto mb-5 wow fadeInLeft" data-wow-delay=".4s"
                     data-wow-duration="2s">
                    <div className="brand-name">{boardData.user_board.title}</div>
                    <div className="brand-tagline"><p>{boardData.user_board.description}</p></div>
                    {props.view_mode === 'craft' && <span>
                        <hr className="horizontal dark"></hr>
                        <span className="live-icon icon-text">Total word count: {total_word_count} words.</span>
                        </span>
                    }
                </div>
                <div
                    className="col-xl-5 col-lg-7 d-flex flex-column m-auto">
                    {boardData.board_cards.length > 0 && boardData.board_cards.map((c, index) => (
                        <Card
                            data={c}
                            key={index}
                            index={index}
                            mode={boardData.mode}
                            auth_mode={props.auth_mode}
                            view_mode={props.view_mode}
                        />
                    ))}
                </div>
            </div>

            {props.view_mode === 'craft' &&
            <BoardComments board_token={activeBoardToken} mode={boardData.mode} auth_mode={props.auth_mode}/>}
        </div>
    );
}
;

