import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";
import Swal from 'sweetalert2';

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function AddCommentModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [description, setDescription] = useState("");
    const [boardToken, setBoardToken] = useState("");
    const [commentID, setCommentID] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const descrMaxChars = 150;

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
        document.getElementById("comment-description-char-count").innerText = e.target.value.length;
    };

    const onChangeBoardToken = (e) => {
        setBoardToken(e.target.value);
    };

    const onChangeCommentID = (e) => {
        setCommentID(e.target.value);
    };

    const onClickModalClose = () => {
        var modal = document.getElementById('add-comment-modal');
        modal.style.display = "none";

        document.getElementById("comment-description-char-count").innerText = '0';

        setDescription("");
        setBoardToken("");
        setCommentID("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickCommentDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            html: '<span style="font-weight: 300;">Your comment will be permanently deleted.</span>',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            color: '#000000',
            customClass: {
                confirmButton: 'btn btn-outline-danger button-text m-1',
                title: 'swal-text',
                cancelButton: 'btn btn-outline-secondary button-text m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteBoardComment(commentID);
            }
        });
    }

    const setCommentModalFormFields = (data) => {

        setBoardToken(data.data.user_board_token);
        setCommentID(data.data.id);
        setDescription(data.data.description);

        document.getElementById("comment-description-char-count").innerText = data.data.description.length;

    }

    window.setCommentModalFormFields = setCommentModalFormFields;

    const addNewComment = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "board_comments/add";
            var comment_data = {"board_comment_id": commentID, "board_comment": { "user_board_token": boardToken, "description": description}};

            if(commentID !== ""){
                api_endpoint = "board_comments/update";
            }

            axios.post(APIService.API_URL + api_endpoint, comment_data,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                        APIService.resetBoardComments();
                        onClickModalClose();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            html: '<span style="font-weight: 300;">Your comment has been saved.</span>',
                            color: '#000000',
                            customClass: {
                                confirmButton: 'btn black-btn button-text m-1',
                                title: 'swal-text'
                            },
                            buttonsStyling: false
                        });

                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal" tabIndex="-1" role="dialog" id="add-comment-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewComment} className="navbar-form" id="form-add-comment-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Add a comment</h5>
                            <button className="close-btn btn btn-icon" type="button" onClick={onClickModalClose}>
                                <i className="material-icons">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <p>Add a comment.</p>
                            <input type="hidden" name="board-token" value={boardToken} onChange={onChangeBoardToken}/>
                            <input type="hidden" name="comment-id" value={commentID} onChange={onChangeCommentID}/>
                            <div className="form-div">
                                <div className="input-group input-group-outline">
                                    <label htmlFor="description" className="text-normal text-dark"><span
                                        className="label-text">Your comment</span>
                                        <Textarea id="form-add-comment-description" name="description"
                                                  value={description} maxLength={descrMaxChars}
                                                  className="form-control" onChange={onChangeDescription}
                                                  placeholder="Short description (optional)..." rows="3"
                                                  validations={[required]}
                                        />
                                    </label>
                                </div>
                                <div className="sub-text float-right">
                                    <span id="comment-description-char-count">0</span> / {descrMaxChars} chars.
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm btn-primary button-text" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {commentID && <button type="button" className="btn btn-sm btn-outline-danger button-text" data-dismiss="modal"
                                    onClick={onClickCommentDelete}>Delete
                            </button>}

                            <button type="button" className="btn btn-sm btn-secondary button-text" data-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}