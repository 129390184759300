import React from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";

export default function BoardComment(props) {

    const showEditCommentModal = () => {
        window.setCommentModalFormFields(props);
        document.getElementById('add-comment-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit your comment';
        document.getElementById('add-comment-modal').style.display = "block";
    };

    const rateComment = (direction) => {

        var rating_data = {"board_comment_id": props.data.id, "up_or_down": direction};
        var api_endpoint = "board_comments/rate";

        axios.post(APIService.API_URL + api_endpoint, rating_data).then((response) => {

            if (response.data.errors) {

            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">Your rating has been saved.</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    return (

        <li className="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-3">
            <div className="checklist-item checklist-item-primary ps-2 ms-3">

                <div className="d-block">
                    {props.data.user_token === window.CurrentUserToken &&
                    <button type="button" rel="tooltip" onClick={showEditCommentModal} title="Edit"
                                            className='btn btn-muted btn-round settings-btn mb-0 mt-3'>
                        <i className="material-icons">settings</i> Edit
                    </button>}
                </div>
                <div className="d-flex align-items-center ms-3 ms-md-4 mt-3 ps-1">
                    <div>
                        {props.data.description}
                    </div>
                    <div>
                        {props.data.id}
                    </div>
                    <div>
                        {props.data.user_board_token}
                    </div>
                    <div>
                        <a href="#" onClick={() => rateComment('up')}> Rate Up </a>
                    </div>
                    <div>
                        <a href="#" onClick={() => rateComment('down')}> Rate Down </a>
                    </div>

                </div>
            </div>
            <hr className="horizontal dark mt-4 mb-0"/>
        </li>

    );
}