import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import APIService from "../../services/api.service";
import axios from "axios";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};


const Login = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const username = e.target.value;
        setEmail(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {


            return axios
                .post(APIService.AUTH_URL + "sign_in", {
                    'user': {
                        email,
                        password,
                    }
                }).then((response) => {
                    if (response.data.errors) {
                        var respStr = APIService.errorsArray(response.data.errors);
                        setMessage(respStr);
                        setLoading(false);
                    } else if (response.headers.authorization) {
                        localStorage.setItem("headers", JSON.stringify(response.headers));
                        sessionStorage.setItem("user", JSON.stringify(response.data));
                        APIService.resetBoards();
                        window.location.assign("/narratives");
                    }

                }).catch((error) => {
                    var resp = APIService.errorResponse(error);
                    setMessage(resp);
                    setLoading(false);
                });


        } else {
            setLoading(false);
        }
    };

    return (

        <section>
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column m-auto">
                            <div className="card card-plain">
                                <div className="card-header text-center mb-2">
                                    <h5 className="font-weight-bolder">Sign In</h5>
                                </div>
                                <div className="card-body mt-2">
                                    <Form onSubmit={handleLogin} ref={form}>
                                        {message && (
                                            <div className="form-group">
                                                <div className="text-primary mb-3" role="alert">
                                                    <div dangerouslySetInnerHTML={{__html: message}}/>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            <label className="form-label w-100"><span className="label-text">Email</span>
                                                <Input type="email" className="form-control"
                                                       placeholder="shay@example.com"
                                                       name="email" value={email}
                                                       onChange={onChangeEmail}
                                                       validations={[required]}
                                                />
                                            </label>
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label w-100"><span className="label-text">Password</span>
                                                <Input type={passwordShown ? "text" : "password"}
                                                       className="form-control" placeholder="Password"
                                                       name="password"
                                                       value={password}
                                                       onChange={onChangePassword}
                                                       validations={[required]}
                                                />
                                                <i className="material-icons-round show-password"
                                                   onClick={togglePassword}>visibility</i>
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-sm black-btn button-text " disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span> Login</span>
                                            </button>
                                        </div>

                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>

                                    </Form>
                                </div>
                                <div className="card-footer text-center pt-4 mt-2">
                                    <p>Not registered yet? <a href="/register" className="font-weight-bold">Sign up</a>
                                    </p>
                                    <p><a href="/forgot-password" className="font-weight-bold">Forgot password?</a></p>
                                    <p><a href="/resend-confirmation" className="font-weight-bold">Resend confirmation
                                        email</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
