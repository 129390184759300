import React, {useState} from "react";
import Navbar from "./Navbar";
import Login from "./Login";
import Register from "./Register";
import ResendConfirmation from "./ResendConfirmation";
import Confirmation from "./Confirmation";
import APIService from "../../services/api.service";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ResendUnlock from "./ResendUnlock";
import Unlock from "./Unlock";
import Account from "./Account";
import {Helmet} from "react-helmet";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import Boards from "./Boards";
import Board from "./Board";
import AddBoardModal from "./AddBoardModal";
import AddCardModal from "./AddCardModal";
import QuickGuide from "./QuickGuide"
import AddCommentModal from "./AddCommentModal";
import UserContacts from "./UserContacts";
import AddContactModal from "./AddContactModal";
import ShowSuggestionsModal from "./ShowSuggestionsModal";
import AddSuggestionModal from "./AddSuggestionModal";
import Footer from "./Footer";
import BoardPDF from "./BoardPDF";
import Portfolio from "./Portfolio";
import SetPortfolioModal from "./SetPortfolioModal";

export default function Dashboard() {

    const [authenticatedUser, setAuthenticatedUser] = useState(APIService.getHeaders());

    // Handle the routing withing the dashboard
    var url = new URL(window.location);
    let activeComponent;
    var activeMenu = "";
    let body = document.getElementsByTagName('body')[0];
    var pageTitle = 'NARRA';
    var showTourLink = false;
    var showFooter = false;
    var footerMode = 'home';
    var auth_mode = "unauth";

    if (window.location.pathname === '/confirmation') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " | Email confirmation";
        showFooter = true;

        var confirmation_token = url.searchParams.get("confirmation_token");
        if (confirmation_token) {
            window.history.replaceState(null, "Email confirmation", "/confirmation?confirmation_token=" + confirmation_token);
            activeComponent = <Confirmation/>;
        } else {
            window.location.assign("/resend-confirmation");
        }
    }

    if (window.location.pathname === '/unlock') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " | Unlock account";
        showFooter = true;

        var unlock_token = url.searchParams.get("unlock_token");
        if (unlock_token) {
            window.history.replaceState(null, "Unlock account", "/unlock?unlock_token=" + unlock_token);
            activeComponent = <Unlock/>;
        } else {
            window.location.assign("/resend-unlock");
        }
    }

    if (window.location.pathname === '/privacy') {
        body.classList.add('bg-gray-200');
        pageTitle = pageTitle + " | Privacy Policy";
        showFooter = true;

        window.history.replaceState(null, "Privacy Policy", "/privacy");
        activeComponent = <PrivacyPolicy/>;

    }

    if (window.location.pathname === '/terms') {
        body.classList.add('bg-gray-200');
        pageTitle = pageTitle + " | Terms & Conditions";
        showFooter = true;

        window.history.replaceState(null, "Terms & Conditions", "/terms");
        activeComponent = <TermsAndConditions/>;

    }

    if (window.location.pathname === '/forgot-password') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " | Send password reset link";
        showFooter = true;

        window.history.replaceState(null, "Send password reset link", "/forgot-password");
        activeComponent = <ForgotPassword/>;
    }

    if (window.location.pathname === '/password/edit') {
        body.classList.remove('bg-gray-200');
        pageTitle = pageTitle + " | Reset password";
        showFooter = true;

        var reset_password_token = url.searchParams.get("reset_password_token");
        if (reset_password_token) {
            window.history.replaceState(null, "Reset password", "/password/edit?reset_password_token=" + reset_password_token);
            activeComponent = <ResetPassword/>;
        } else {
            window.location.assign("/forgot-password");
        }
    }

    if (window.location.pathname.includes("/publish/")) {
        var boardToken = window.location.pathname.split("/")[2];

        pageTitle = pageTitle + " | Publish | " + boardToken;
        window.history.replaceState(null, "Publish | " + boardToken, window.location.pathname);

        activeComponent = <Board token={boardToken} auth_mode={'publ'} view_mode={'publish'}/>;
        activeMenu = 'none';
        showFooter = true;
        footerMode = 'publ';
    }


    if (window.location.pathname.includes("/portfolio/view")) {
        var userToken = window.location.pathname.split("/")[3];

        pageTitle = pageTitle + " | Portfolio | " + userToken;
        window.history.replaceState(null, "Portfolio | " + userToken, window.location.pathname);

        activeComponent = <Portfolio token={userToken} view_mode={'view'}/>;
        activeMenu = 'none';
        showFooter = true;
        footerMode = 'publ';
    }


    if (!authenticatedUser) {
        window.CurrentUserToken = "";

        body.classList.remove('bg-gray-200');

        auth_mode = "unauth";
        showFooter = true;

        if (window.location.pathname === '/register') {
            pageTitle = pageTitle + " | Sign up";
            window.history.replaceState(null, "Sign up", "/register");
            activeComponent = <Register/>;
        } else if (window.location.pathname === '/resend-unlock') {
            pageTitle = pageTitle + " | Resend unlock link";
            window.history.replaceState(null, "Resend unlock link", "/resend-unlock");
            activeComponent = <ResendUnlock/>;
        } else if (window.location.pathname === '/resend-confirmation') {
            pageTitle = pageTitle + " | Resend email confirmation";
            window.history.replaceState(null, "Resend email confirmation", "/resend-confirmation");
            activeComponent = <ResendConfirmation/>;
        } else if (window.location.pathname.includes("/craft/")) {
            var boardToken = window.location.pathname.split("/")[2];
            pageTitle = pageTitle + " | Craft | " + boardToken;

            var url_inv_token = "";
            var invitation_token = url.searchParams.get("invitation_token");
            if(invitation_token){
                url_inv_token =  "?invitation_token="+invitation_token;
            }
            window.history.replaceState(null, "Craft | " + boardToken, window.location.pathname + url_inv_token);

            activeComponent = <Board token={boardToken} auth_mode={auth_mode} view_mode={'craft'}/>;
            activeMenu = 'boards';
            showFooter = false;

        } else if (window.location.pathname === '/forgot-password' ||
            window.location.pathname === '/password/edit' ||
            window.location.pathname === '/confirmation' ||
            window.location.pathname === '/unlock' ||
            window.location.pathname === '/privacy' ||
            window.location.pathname === '/terms' ||
            window.location.pathname.includes("/publish/") ||
            window.location.pathname.includes("/portfolio/view")) {
            // do nothing - these URLs are already handled above
        } else {
            pageTitle = pageTitle + " | Sign in";
            window.history.replaceState(null, "Sign in", "/login");
            activeComponent = <Login/>;
        }
    }

    function reset_user_session() {
        if (!APIService.getBoards()) {
            APIService.resetBoards();
        }

        if (!APIService.getCurrentUser()) {
            APIService.resetCurrentUser();
        }
    }

    if (authenticatedUser) {

        body.classList.add('g-sidenav-show');
        body.classList.add('bg-gray-200');

        auth_mode = "auth";

        if (window.location.pathname === '/logout') {
            APIService.logout();
            window.location.assign("/");
        } else if (window.location.pathname === '/account') {
            reset_user_session();
            pageTitle = pageTitle + " | Account";
            window.history.replaceState(null, "Account", "/account");
            activeComponent = <Account/>;
            activeMenu = 'account';
        } else if (window.location.pathname === '/forgot-password' ||
            window.location.pathname === '/password/edit' ||
            window.location.pathname === '/confirmation' ||
            window.location.pathname === '/unlock' ||
            window.location.pathname.includes("/publish/") ||
            window.location.pathname.includes("/portfolio/view")) {
            body.classList.remove('bg-gray-200');
        } else if (window.location.pathname === '/privacy' ||
            window.location.pathname === '/terms') {
        } else if (window.location.pathname === "/contributors") {
            reset_user_session();
            pageTitle = pageTitle + " | Contributors";
            window.history.replaceState(null, "Contributors", window.location.pathname);
            activeComponent = <UserContacts />;
            activeMenu = 'contacts'
        } else if (window.location.pathname.includes("/craft/")) {
            reset_user_session();
            window.CurrentUserToken = APIService.getCurrentUser().user.token;

            var boardToken = window.location.pathname.split("/")[2];
            pageTitle = pageTitle + " | Craft | " + boardToken;

            var url_inv_token = "";
            var invitation_token = url.searchParams.get("invitation_token");
            if(invitation_token){
                url_inv_token =  "?invitation_token="+invitation_token;
            }
            window.history.replaceState(null, "Craft | " + boardToken, window.location.pathname + url_inv_token);

            activeComponent = <Board token={boardToken} auth_mode={auth_mode} view_mode={'craft'}/>;
            activeMenu = 'boards';
        } else if (window.location.pathname.includes("/preview/")) {
            reset_user_session();
            var boardToken = window.location.pathname.split("/")[2];
            pageTitle = pageTitle + " | Preview | " + boardToken;

            window.history.replaceState(null, "Preview | " + boardToken, window.location.pathname);

            activeComponent = <Board token={boardToken} auth_mode={auth_mode} view_mode={'preview'}/>;
            activeMenu = 'preview';
            showFooter = true;
            footerMode = 'publ';

        } else if (window.location.pathname.includes("/pdf/")) {
            reset_user_session();
            var boardToken = window.location.pathname.split("/")[2];

            window.history.replaceState(null, "PDF | " + boardToken, window.location.pathname);

            return <BoardPDF token={boardToken} />;

        } else if (window.location.pathname.includes("/portfolio/preview")) {
            reset_user_session();
            pageTitle = pageTitle + " | Preview Portfolio ";

            window.history.replaceState(null, "Portfolio Preview ", window.location.pathname);

            activeComponent = <Portfolio view_mode={'preview'}/>;
            activeMenu = 'preview';
            showFooter = true;
            footerMode = 'publ';

        } else if (window.location.pathname.includes("/portfolio/set")) {
            reset_user_session();
            window.CurrentUserToken = APIService.getCurrentUser().user.token;

            pageTitle = pageTitle + " | Set Portfolio ";

            window.history.replaceState(null, "Set Portfolio ", window.location.pathname);

            activeComponent = <Portfolio view_mode={'set'}/>;
            activeMenu = 'portfolio';

        }  else {
            reset_user_session();
            pageTitle = pageTitle + " | Narratives";
            window.history.replaceState(null, "Narratives", "/narratives");
            activeComponent = <Boards/>;
            activeMenu = 'boards'
        }
    }


    return (
        <div className="wrapper">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Navbar showTourLink={showTourLink} auth_mode={auth_mode} activeMenu={activeMenu} />
            <main className="main">
                <div className="container-xl">
                    {activeComponent}
                </div>
                {showFooter && <Footer mode={footerMode}/>}
            </main>

            <AddBoardModal/>
            <SetPortfolioModal/>
            <AddCardModal />
            <AddCommentModal />
            <AddContactModal />
            <AddSuggestionModal />
            <ShowSuggestionsModal />
            <QuickGuide />
        </div>

    );

}
