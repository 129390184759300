import axios from "axios";
import Swal from "sweetalert2";

var AUTH_URL = "http://0.0.0.0:3000/";
var API_URL = "http://0.0.0.0:3000/api/v1/";

if (window.location.hostname === "www.narra.cc") {
    AUTH_URL = "https://api.narra.cc/";
    API_URL = "https://api.narra.cc/api/v1/";
} else if (window.location.hostname === "do6nh5wbp7uxd.cloudfront.net") {
    AUTH_URL = "https://api-staging.narra.cc/";
    API_URL = "https://api-staging.narra.cc/api/v1/";
} else if(window.location.hostname === "192.168.43.51"){
    AUTH_URL = "http://192.168.43.51:3000/";
    API_URL = "http://192.168.43.51:3000/api/v1/";
}

const logout = () => {

    if (getHeaders()) {
        axios.delete(AUTH_URL + "sign_out",
            {headers: {"Authorization": getHeaders().authorization}}
        ).then((response) => {
            // do nothing
        }).catch((error) => {
            // do nothing
        });
    }

    localStorage.removeItem("headers");
    localStorage.removeItem("tour_status");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user_boards");
    sessionStorage.removeItem("user_contacts");
};

const getCurrentUser = () => {
    return JSON.parse(sessionStorage.getItem("user"));
};

const resetCurrentUser = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user/get_user",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                logout_then_redirect_back();
            } else {
                sessionStorage.setItem("user", JSON.stringify(response.data));
                window.location.assign("/narratives");
            }

        }).catch((error) => {
            logout_then_redirect_back();
        });
    }

}

const logout_then_redirect_back = () => {
    logout();
    window.location.assign(window.location.pathname+window.location.search);
}

const getHeaders = () => {
    return JSON.parse(localStorage.getItem("headers"));
};

const getBoards = () => {
    return JSON.parse(sessionStorage.getItem("user_boards"));
};

const resetBoards = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user_boards/get",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                sessionStorage.setItem("user_boards", JSON.stringify(response.data.user_boards));
                window.updateBoardsData(response.data.user_boards);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteBoard = (user_board_token) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_boards/delete", {"user_board_token": user_board_token},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                if (window.location.pathname.includes("/craft/")) {
                    sessionStorage.removeItem("user_boards");
                    window.location.assign("/narratives");
                } else {
                    resetBoards();
                }
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const getContacts = () => {
    return JSON.parse(sessionStorage.getItem("user_contacts"));
};

const resetContacts = () => {

    if (getHeaders()) {
        axios.get(API_URL + "user_contacts/get",
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                sessionStorage.setItem("user_contacts", JSON.stringify(response.data.user_contacts));
                window.updateContactsData(getContacts());
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteContact = (contact_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "user_contacts/delete", {"user_contact_id": contact_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                resetContacts();
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const resetBoardCards = () => {

    if (getHeaders() && window.activeBoardToken) {

        axios.get(API_URL + "user_boards/get_by_token", {
            params: {"token": window.activeBoardToken},
            headers: {"Authorization": getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                return [];
            } else {
                window.updateBoardData(response.data.user_board);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const resetBoardComments = () => {

    if (getHeaders() && window.activeBoardToken) {

        axios.get(API_URL + "board_comments/get_by_board_token", {
            params: {"user_board_token": window.activeBoardToken},
            headers: {"Authorization": getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                return [];
            } else {
                window.updateBoardCommentData(response.data.board_comments);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteBoardComment = (comment_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "board_comments/delete", {"board_comment_id": comment_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                resetBoardComments();
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const resetCardSuggestions = () => {

    if (getHeaders() && window.activeCardID) {

        axios.get(API_URL + "card_suggestions/get_by_card_id", {
            params: {"board_card_id": window.activeCardID},
            headers: {"Authorization": getHeaders().authorization}
        }).then((response) => {

            if (response.data.errors) {
                return [];
            } else {
                window.updateCardSuggestionData(response.data.card_suggestions);
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const deleteCardSuggestion = (suggestion_id) => {

    if (getHeaders()) {
        axios.post(API_URL + "card_suggestions/delete", {"card_suggestion_id": suggestion_id},
            {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {
                // do nothing
            } else {
                resetCardSuggestions();
            }

        }).catch((error) => {
            // do nothing
        });
    }
}

const errorsArray = (errorObj) => {

    var respStr = 'Oh no! ';
    for (var key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {

            var emoji = '&#128073;';
            if (key === 'email') {
                emoji = '&#128075;';
            } else if (key === 'password') {
                emoji = '&#128551;';
            }

            if (errorObj[0]) {
                if (errorObj[0].attribute === 'unlock_token' && errorObj[0].type === 'invalid') {
                    respStr = respStr + 'unlock_token' + "&nbsp;" + emoji + "&nbsp; " + 'is invalid.' + "<br/><br/>";
                }
            } else {
                respStr = respStr + key + "&nbsp;" + emoji + "&nbsp; " + errorObj[key] + "<br/><br/>";
            }
        }
    }
    return respStr;
}

const errorResponse = (error) => {

    var respStr = '';
    if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
        logout();
        window.location.assign("/login");
    } else if (error.response && error.response.data.error) {
        respStr = error.response.data.error;
    } else {
        respStr = "Oops! Something went wrong. Please try again.";
    }

    return respStr;

}

const getTourStatus = () => {
    return localStorage.getItem("tour_status");
}

const setTourStatus = () => {
    localStorage.setItem("tour_status", true)
}

const cloneBoard = (boardToken) => {

    Swal.fire({
        icon: 'info',
        title: 'Confirm',
        html: '<span style="font-weight: 300;">Create a copy of this narrative?</span>',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        color: '#000000',
        customClass: {
            confirmButton: 'btn black-btn button-text m-1',
            title: 'swal-text',
            cancelButton: 'btn btn-outline-secondary button-text m-1'
        },
        buttonsStyling: false
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

            var board_data = {"user_board_token": boardToken};
            var api_endpoint = "user_boards/clone";

            axios.post(API_URL + api_endpoint, board_data,
                {headers: {"Authorization": getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        html: '<span style="font-weight: 300;">Something went wrong.</span>',
                        color: '#000000',
                        customClass: {
                            confirmButton: 'btn black-btn button-text m-1',
                            title: 'swal-text'
                        },
                        buttonsStyling: false
                    });
                } else {
                    sessionStorage.removeItem("user_boards");
                    window.location.assign("/craft/" + response.data.user_board_token);
                }

            }).catch((error) => {
            });

        }
    });
}

export default {
    AUTH_URL,
    API_URL,
    logout,
    getCurrentUser,
    resetCurrentUser,
    getHeaders,
    getBoards,
    resetBoards,
    deleteBoard,
    getContacts,
    resetContacts,
    deleteContact,
    resetBoardCards,
    resetBoardComments,
    deleteBoardComment,
    resetCardSuggestions,
    deleteCardSuggestion,
    errorsArray,
    errorResponse,
    setTourStatus,
    getTourStatus,
    cloneBoard
};

