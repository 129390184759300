import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Textarea from "react-validation/build/textarea";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

export default function SetPortfolioModal() {
    
    const form = useRef();
    const checkBtn = useRef();

    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    
    const bioMaxChars = 300;
    const nameMaxChars = 75;

    const onChangeName = (e) => {
        setName(e.target.value);
        document.getElementById("portfolio-name-char-count").innerText = e.target.value.length;
    };

    const onChangeBio = (e) => {
        setBio(e.target.value);
        document.getElementById("portfolio-bio-char-count").innerText = e.target.value.length;
    };
    

    const onClickModalClose = () => {

        document.getElementById("portfolio-name-char-count").innerText = '0';
        document.getElementById("portfolio-bio-char-count").innerText = '0';

        setName("");
        setBio("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

  
    const setPortfolioModalFormFields = (data) => {

        setName(data.data.profile.name ? data.data.profile.name : '');
        setBio(data.data.profile.bio ? data.data.profile.bio : '');
       
        document.getElementById("portfolio-name-char-count").innerText = data.data.profile.name ? data.data.profile.name.length : 0;
        document.getElementById("portfolio-bio-char-count").innerText = data.data.profile.bio ? data.data.profile.bio.length : 0;

    }

    window.setPortfolioModalFormFields = setPortfolioModalFormFields;

    const setPortfolio = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);
        
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "user_portfolio/update";
            var portfolio_data = {
                "user_portfolio": {
                    "name": name, "bio": bio
                }
            };
            
            axios.post(APIService.API_URL + api_endpoint, portfolio_data,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                        window.updateUserPortfolio(response.data.user_portfolio);
                        document.getElementById('set-portfolio-modal-close').click();

                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (

        <div className="modal fade" tabIndex="-1" role="dialog" id="set-portfolio-modal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={setPortfolio} className="navbar-form" id="form-set-portfolio-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Set portfolio profile</h5>
                            <button id="set-portfolio-modal-close" className="close-btn btn btn-icon" type="button"
                                    data-bs-dismiss="modal" onClick={onClickModalClose}>
                                <i className="material-icons-round">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label w-100"><span
                                    className="label-text">Name</span>
                                    <Input type="text" id="form-set-portfolio-name" name="name"
                                           className="form-control mt-1"
                                           value={name} maxLength={nameMaxChars} placeholder="Name here..."
                                           onChange={onChangeName}
                                           validations={[required]}/>
                                </label>

                                <div className="sub-text float-right">
                                    <span id="portfolio-name-char-count">0</span> / {nameMaxChars} chars.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="bio" className="form-label w-100"><span
                                    className="label-text">Short bio</span>
                                    <Textarea id="form-set-portfolio-bio" name="bio"
                                              value={bio} maxLength={bioMaxChars}
                                              className="form-control mt-1" onChange={onChangeBio}
                                              placeholder="Short bio..." rows="3"
                                    />
                                </label>
                                <div className="sub-text float-right">
                                    <span id="portfolio-bio-char-count">0</span> / {bioMaxChars} chars.
                                </div>
                            </div>
                            
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm black-btn button-text" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>

                            <button type="button" className="btn btn-sm btn-outline-secondary button-text"
                                    data-bs-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>

    )
}