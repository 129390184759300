import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail} from "validator";

import APIService from "../../services/api.service";
import axios from "axios";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="text-primary" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 8 || value.length > 40) {
        return (
            <div className="text-primary" role="alert">
                The password must be between 8 and 40 characters.
            </div>
        );
    }
};

const vconfirmpassword = (value) => {
    var pw = document.getElementById('password').value
    if (value !== pw) {
        return (
            <div className="text-primary" role="alert">
                The passwords should match.
            </div>
        );
    }
};


const Register = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleRegister = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            axios.post(APIService.AUTH_URL + "sign_up", {
                'user': {
                    email,
                    password,
                    password_confirmation: confirmPassword
                }
            }).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else if (response.headers.authorization) {
                    localStorage.setItem("headers", JSON.stringify(response.headers));
                    sessionStorage.setItem("user", JSON.stringify(response.data));
                    window.location.assign("/narratives");
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });

        } else {
            setLoading(false);
        }
    };

    return (
        <section>
            <div className="page-header ">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column m-auto">
                            <div className="card card-plain">
                                <div className="card-header text-center mb-2">
                                    <h5 className="font-weight-bolder">Sign up</h5>
                                </div>
                                <div className="card-body mt-2">
                                    <Form onSubmit={handleRegister} ref={form}>

                                        {message && (
                                            <div className="form-group">
                                                <div className="text-primary mb-3" role="alert">
                                                    <div dangerouslySetInnerHTML={{__html: message}}/>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            <label className="form-label w-100"><span className="label-text">Email</span>
                                                <Input type="email" className="form-control"
                                                       placeholder="shay@example.com"
                                                       name="email" value={email}
                                                       onChange={onChangeEmail}
                                                       validations={[required, validEmail]}
                                                />
                                            </label>
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label w-100"><span className="label-text">Password</span>
                                                <Input type={passwordShown ? "text" : "password"}
                                                       className="form-control" placeholder="Password"
                                                       name="password" id="password"
                                                       value={password}
                                                       onChange={onChangePassword}
                                                       validations={[required, vpassword]}
                                                />
                                                <i className="material-icons-round show-password"
                                                   onClick={togglePassword}>visibility</i>
                                            </label>
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label w-100"><span className="label-text">Confirm password</span>
                                                <Input type={passwordShown ? "text" : "password"}
                                                       className="form-control"
                                                       placeholder="Confirm password"
                                                       name="confirmPassword"
                                                       value={confirmPassword}
                                                       onChange={onChangeConfirmPassword}
                                                       validations={[required, vconfirmpassword]}
                                                />
                                                <i className="material-icons-round show-password"
                                                   onClick={togglePassword}>visibility</i>
                                            </label>
                                        </div>

                                        <div className="text-center">
                                            <button className="btn btn-sm black-btn button-text"
                                                    disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span> Sign up</span>
                                            </button>
                                        </div>
                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>

                                    </Form>
                                </div>
                                <div className="card-footer text-center pt-4 mt-2">
                                    <p>Already registered? <a href="/login"
                                                              className="font-weight-bold">Sign
                                        in</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Register;
