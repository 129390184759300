import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import axios from "axios";

export default function Boards() {

    const [loading, setLoading] = useState(true);
    const [boardsData, updateBoardsData] = useState();
    window.updateBoardsData = updateBoardsData;

    useEffect(() => {

        axios.get(APIService.API_URL + "user_boards/get",
            {headers: {"Authorization": APIService.getHeaders().authorization}})
            .then((response) => {

                if (response.data.errors) {
                    // do nothing
                } else {
                    sessionStorage.setItem("user_boards", JSON.stringify(response.data.user_boards));

                    updateBoardsData(APIService.getBoards());
                    setLoading(false);
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    const onClickBoard = (e) => {
        window.location.assign("/craft/" + e);
    };

    const goToContributors = () => {
        window.location.assign("/contributors");
    }

    function showAddBoardModal() {
        document.getElementById('add-board-modal').getElementsByClassName("modal-title")[0].innerText = 'Create a narrative';
    }

    const cloneBoard = (e) => {
        APIService.cloneBoard(e);
    };

    const pdfBoard = (e) => {
        window.open("/pdf/" + e);
    };

    return (
        <div>
            <div className="d-flex">
                <div className="my-auto">
                    <h4 className="mb-0 page-title">Your narratives</h4>
                </div>
                <div className="ms-auto d-none d-md-block">

                </div>
                <div className="d-none d-lg-block ms-auto">
                    <button className="btn btn-sm btn-outline-secondary button-text" onClick={goToContributors}>
                        <i className="material-icons-outlined icon opacity-10">group</i>&nbsp;
                        CONTRIBUTORS
                    </button>
                </div>
            </div>
            <div className="d-block d-lg-none mt-3">
                <button className="btn btn-sm btn-outline-secondary button-text" onClick={goToContributors}>
                    <i className="material-icons-outlined icon opacity-10">group</i>&nbsp;
                    CONTRIBUTORS
                </button>
            </div>
            <hr className="horizontal dark"></hr>
        <div className="row row mt-lg-4 mt-2">
            {boardsData && boardsData.length > 0 && boardsData.map((c, index) => (
                <div key={index} className="col-lg-4 col-md-6 mb-4">
                    <div className="content-box-2">
                        <div className="d-flex">
                            <div className="my-auto">
                                <h6 className="board-name" onClick={() => onClickBoard(c.token)}>{c.title}</h6>
                            </div>
                            <div className="ms-auto">
                                <button className="btn btn-link text-secondary" data-bs-toggle="dropdown"
                                        id="dropdownMenuLink2">
                                    <i className="fa fa-ellipsis-v text-lg"></i>
                                </button>
                                <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink2">
                                    <li><a onClick={() => onClickBoard(c.token)} className="dropdown-item"
                                           href="#">Open</a></li>
                                    <li><a onClick={() => cloneBoard(c.token)} className="dropdown-item"
                                           href="#">Clone</a></li>
                                    <li><a onClick={() => pdfBoard(c.token)} className="dropdown-item"
                                           href="#">PDF</a></li>
                                </ul>
                            </div>
                        </div>
                        <p>{c.description}</p>

                        <hr className="line"/>
                        <ul className="reactions-list">
                            <li>
                                    <span className="icon-text">
                                    <i className="material-icons-outlined icon opacity-10">group</i>&nbsp;&nbsp;&nbsp;
                                        {c.num_board_contacts} contributor(s)
                                        </span>
                            </li>
                            <li>
                                    <span className="icon-text">
                                    <i className="material-icons-outlined icon opacity-10">chat</i>&nbsp;&nbsp;&nbsp;
                                        {c.num_suggestions} suggestion(s)
                                        </span>
                            </li>
                        </ul>
                    </div>
                </div>
            ))}
            {(!boardsData || boardsData.length < 1) &&
            <div className="ms-auto">
                <button className="btn btn-sm black-btn button-text"
                        data-bs-toggle="modal" data-bs-target="#add-board-modal"
                        onClick={showAddBoardModal}>+&nbsp; Add your first narrative
                </button>
            </div>}
        </div>
        </div>
    )
}