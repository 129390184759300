import React from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";

export default function Card(props) {

    const showEditCardModal = () => {
        window.setCardModalFormFields(props.data);
        document.getElementById('add-card-modal').getElementsByClassName("modal-title")[0].innerText = 'Edit section';
    };

    const showCardSuggestionsModal = () => {
        window.setSuggestionsModalCardID(props);
    }

    var word_count = 0;
    if(props.data.text.trim() !== "") {
        word_count = props.data.text.trim().split(/\s+/).length;
    }

    const rateCard = (direction) => {

        var rating_data = {"board_card_id": props.data.id, "up_or_down": direction};
        var api_endpoint = "board_cards/rate";

        axios.post(APIService.API_URL + api_endpoint, rating_data).then((response) => {

            if (response.data.errors) {

            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">Your rating has been saved.</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    if (word_count === 0 && props.view_mode !== 'craft') {
        return <div/>;
    }

    return (

        <div key={props.index} className="content-box wow fadeInRight" data-wow-duration="2s" data-wow-delay=".2s">
            <div className="d-flex">
                <span className="card-label my-auto">{props.data.card_type.label}</span>
                {props.view_mode === 'craft' &&
                <ul className="overlay ms-auto">
                    <li className="d-none">
                        <a href="#" onClick={() => rateCard('up')}><span className="live-icon icon-text">
                                    <i className="material-icons-outlined icon opacity-10">thumb_up</i>&nbsp;
                            450
                                    </span>
                        </a>
                    </li>
                    <li className="d-none">
                        <a href="#" onClick={() => rateCard('down')}><span className="live-icon icon-text">
                                    <i className="material-icons-outlined icon opacity-10">thumb_down</i>&nbsp;
                            100
                        </span></a>
                    </li>
                    <li className="">
                        <span className="live-icon icon-text">
                            {word_count} words
                        </span>
                    </li>
                    <li>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#show-suggestions-modal"
                           onClick={showCardSuggestionsModal}>
                            <span className="live-icon icon-text">
                                    <i className="material-icons-outlined icon opacity-10">chat</i>&nbsp;
                                {props.data.num_suggestions}
                        </span></a>
                    </li>
                    {props.mode === "owner" &&
                    <li>
                        <a href="#" data-bs-toggle="modal" onClick={showEditCardModal}
                           data-bs-target="#add-card-modal"><span className="live-icon icon-text">
                                    <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                            EDIT
                                    </span></a>
                    </li>
                    }
                </ul>}
            </div>
            <div className="card-text">{props.data.text}</div>
        </div>
    );
}