import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import BoardComment from "./BoardComment";
import Swal from "sweetalert2";

export default function BoardComments(props) {

    const activeBoardToken = props.board_token;
    const [commentsData, updateCommentsData] = useState();
    const [loading, setLoading] = useState(true);

    window.updateBoardCommentData = updateCommentsData;

    var api_endpoint = "board_comments/get_by_board_token_public";
    var headersData = {};
    if (props.auth_mode == "auth") {
        api_endpoint = "board_comments/get_by_board_token";
        headersData =  {"Authorization": APIService.getHeaders().authorization};
    }

    var paramsData =  {"user_board_token": activeBoardToken};
    var url = new URL(window.location);
    var invitation_token = url.searchParams.get("invitation_token");
    if(invitation_token != ""){
        paramsData =  {"user_board_token": activeBoardToken, "invitation_token": invitation_token };
    }

    useEffect(() => {
        axios.get(APIService.API_URL + api_endpoint , { params: paramsData,
            headers: headersData
        }).then((response) => {

            if (response.data.errors) {
                // do nothing
                return [];
            } else {
                updateCommentsData(response.data.board_comments);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, [props]);

    const showAddCommentModal = () => {

        if(props.auth_mode != "auth"){

            Swal.fire({
                icon: 'info',
                title: 'Almost there...',
                html: '<span style="font-weight: 300;">You need to sign in to comment on this board.</span>',
                showCancelButton: true,
                confirmButtonText: 'Sign in',
                color: '#000000',
                customClass: {
                    confirmButton: 'btn black-btn button-text m-1',
                    title: 'swal-text',
                    cancelButton: 'btn btn-outline-secondary button-text m-1'
                },
                buttonsStyling: false
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.assign("/login");
                }
            });

        } else {
            window.setCommentModalFormFields({data: {user_board_token: activeBoardToken, description: "", id: ""}});
            document.getElementById('add-comment-modal').getElementsByClassName("modal-title")[0].innerText = 'Add a comment';
            document.getElementById('add-comment-modal').style.display = "block";
        }
    };

    if (loading) {
        return <div/>;
    }

    return (
        <div className="d-none row mt-lg-4 mt-2 mb-4">
            <div className="card">
                <div className="card-header p-3">
                    <div className="d-flex mt-n2">
                        <div
                            className='avatar avatar-xl border-radius-xl p-2 mt-n4 card-header-icon-bg-rose'>
                            <i className="material-icons-round opacity-10">history_edu</i>
                        </div>
                        <div className="ms-3 my-auto">
                            <h5 className="mb-0">Comments</h5>
                        </div>
                        <div className="ms-auto d-none d-md-block">
                            <button className="btn btn-sm black-btn button-text" onClick={showAddCommentModal}>Add a comment
                            </button>
                        </div>
                    </div>
                    <hr className="horizontal dark"/>
                </div>

                <div className="card-body">
                    {commentsData.length > 0 && <ul className="list-group list-group-flush">

                        {commentsData.map((c, index) => (
                            <BoardComment
                                data={c}
                                key={index}
                                index={index}
                                auth_mode={props.auth_mode}
                                mode={props.mode}
                            />
                        ))}

                    </ul>}
                    {commentsData.length < 1 &&
                    <p>This board has no comments yet.</p>
                    }

                </div>
            </div>
        </div>
    );
};

