import React, {useState, useRef} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


const ResendUnlock = (props) => {
    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeEmail = (e) => {
        const username = e.target.value;
        setEmail(username);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            return axios
                .post(APIService.AUTH_URL + "unlock", {
                    'user': {
                        email
                    }
                }).then((response) => {

                    if (response.data.errors) {

                        var respStr = APIService.errorsArray(response.data.errors);
                        setMessage(respStr);
                        setLoading(false);
                        setSuccessful(false);
                    } else {
                        setLoading(false);
                        setSuccessful(true);
                        setMessage("");
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            html: '<span style="font-weight: 300;">Your unlock link has been resent. ' +
                                'Please check your email and follow the instructions.</span>',
                            color: '#000000',
                            customClass: {
                                confirmButton: 'btn black-btn button-text m-1',
                                title: 'swal-text'
                            },
                            buttonsStyling: false
                        });
                    }

                }).catch((error) => {
                    var resp = APIService.errorResponse(error);
                    setMessage(resp);
                    setLoading(false);
                    setSuccessful(false);
                });


        } else {
            setLoading(false);
        }
    };

    return (

        <section>
            <div className="page-header ">
                <div className="container">
                    <div className="row">

                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column m-auto">
                            <div className="card card-plain">
                                <div className="card-header text-center mb-2">
                                    <h5 className="font-weight-bolder">Resend unlock email</h5>
                                </div>
                                <div className="card-body mt-2">
                                    <Form onSubmit={handleSubmit} ref={form}>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={
                                                        successful ? "alert alert-success text-white" : "text-primary mb-3"
                                                    }
                                                    role="alert"
                                                >
                                                    <div dangerouslySetInnerHTML={{__html: message}}/>
                                                </div>
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            <label className="form-label w-100"><span className="label-text">Email</span>
                                                <Input type="email" className="form-control"
                                                       placeholder="shay@example.com"
                                                       name="email" value={email}
                                                       onChange={onChangeEmail}
                                                       validations={[required]}
                                                />
                                            </label>
                                        </div>


                                        <div className="text-center">
                                            <button className="btn btn-sm black-btn button-text" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span> Submit</span>
                                            </button>
                                        </div>

                                        <CheckButton style={{display: "none"}} ref={checkBtn}/>

                                    </Form>
                                </div>
                                <div className="card-footer text-center pt-4 mt-2">
                                    <p><a href="/login" className="font-weight-bold">Sign in</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResendUnlock;
