import React, {useRef, useState} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Swal from 'sweetalert2';
import {isEmail} from "validator";

const required = (value) => {
    if (!value) {
        return (
            <div className="text-primary" role="alert">
                This field is required.
            </div>
        );
    }
};

const isValidContact = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="text-primary" role="alert">
                Enter valid email address.
            </div>
        );
    }
};

export default function AddContactModal() {

    const form = useRef();
    const checkBtn = useRef();

    const [contactID, setContactID] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const emailMaxChars = 150
    const nameMaxChars = 150;

    const onChangeContactID = (e) => {
        setContactID(e.target.value);
    };

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        document.getElementById("contact-email-char-count").innerText = e.target.value.length;
    };

    const onChangeName = (e) => {
        setName(e.target.value);
        document.getElementById("contact-name-char-count").innerText = e.target.value.length;
    };

    const onClickModalClose = () => {

        document.getElementById("contact-email-char-count").innerText = '0';
        document.getElementById("contact-name-char-count").innerText = '0';

        setContactID("");
        setEmail("");
        setName("");

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    };

    const onClickContactDelete = () => {

        onClickModalClose();

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            html: '<span style="font-weight: 300;">Contributor details will be permanently deleted.</span>',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            color: '#000000',
            customClass: {
                confirmButton: 'btn btn-outline-danger button-text m-1',
                title: 'swal-text',
                cancelButton: 'btn btn-outline-secondary button-text m-1'
            },
            buttonsStyling: false
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                APIService.deleteContact(contactID);
            }
        });
    }

    const setContactModalFormFields = (data) => {

        document.getElementById("contact-email-char-count").innerText = data.data.email.length;
        document.getElementById("contact-name-char-count").innerText = data.data.name.length;

        setContactID(data.data.id);
        setEmail(data.data.email)
        setName(data.data.name);

        setLoading(false);
        setSuccessful(false);
        setMessage("");

    }

    window.setContactModalFormFields = setContactModalFormFields;

    const addNewContact = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            var api_endpoint = "user_contacts/add";
            var contact = {
                "user_contact_id": contactID,
                "contact": {
                    "email": email, "name": name
                }
            }

            if (contactID !== "") {
                api_endpoint = "user_contacts/update";
            }

            axios.post(APIService.API_URL + api_endpoint, contact,
                {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

                if (response.data.errors) {
                    var respStr = APIService.errorsArray(response.data.errors);
                    setMessage(respStr);
                    setLoading(false);
                    setSuccessful(false);
                } else {
                    APIService.resetContacts();
                    document.getElementById('add-contact-modal-close').click();
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        html: '<span style="font-weight: 300;">Contributor details have been saved.</span>',
                        color: '#000000',
                        customClass: {
                            confirmButton: 'btn black-btn button-text m-1',
                            title: 'swal-text'
                        },
                        buttonsStyling: false
                    });
                }

            }).catch((error) => {
                var resp = APIService.errorResponse(error);
                setMessage(resp);
                setLoading(false);
                setSuccessful(false);
            });
        } else {
            setLoading(false);
        }
    }


    return (
        <div className="modal fade" tabIndex="-1" role="dialog" id="add-contact-modal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <Form ref={form} onSubmit={addNewContact} className="navbar-form" id="form-add-contact-modal">
                        <div className="modal-header">
                            <h5 className="modal-title">Add a contact</h5>
                            <button id="add-contact-modal-close" className="close-btn btn btn-icon" type="button"
                                   data-bs-dismiss="modal" onClick={onClickModalClose}>
                                <i className="material-icons-round">cancel</i>
                            </button>
                        </div>
                        <div className="modal-body">

                            {message && (
                                <div className="form-group">
                                    <div
                                        className={
                                            successful ? "alert alert-success text-white" : "text-primary mb-3"
                                        }
                                        role="alert"
                                    >
                                        <div dangerouslySetInnerHTML={{__html: message}}/>
                                    </div>
                                </div>
                            )}
                            <input type="hidden" name="contact-id" value={contactID} onChange={onChangeContactID}/>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label w-100"><span className="label-text">Email</span>
                                        <Input type="text" id="form-add-contact-email" name="email"
                                               className="form-control" value={email}
                                               maxLength={emailMaxChars} placeholder="Email address..."
                                               onChange={onChangeEmail}
                                               validations={[required, isValidContact]}/>

                                    </label>

                                <div className="sub-text float-right">
                                    <span id="contact-email-char-count">0</span> / {emailMaxChars} chars.
                                </div>
                            </div>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label w-100"><span className="label-text">Name</span>
                                        <Input type="text" id="form-add-contact-name" name="contact"
                                               className="form-control" value={name}
                                               maxLength={nameMaxChars} placeholder="Name..."
                                               onChange={onChangeName} />

                                    </label>

                                <div className="sub-text float-right">
                                    <span id="contact-name-char-count">0</span> / {nameMaxChars} chars.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-sm black-btn button-text" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span> Save</span>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                            {contactID && <button type="button" className="btn btn-sm btn-outline-danger button-text" data-bs-dismiss="modal"
                                                  onClick={onClickContactDelete}>Delete
                            </button>}
                            <button type="button" className="btn btn-sm btn-outline-secondary button-text" data-bs-dismiss="modal"
                                    onClick={onClickModalClose}>Cancel
                            </button>

                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}