import React, {useState, useEffect} from "react";
import axios from "axios";
import APIService from "../../services/api.service";
import Swal from "sweetalert2";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    PDFViewer,
} from "@react-pdf/renderer";
import Poppins from "../../fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../fonts/Poppins-Bold.ttf";

export default function BoardPDF(props) {

    const [boardData, updateBoardData] = useState();
    const [loading, setLoading] = useState(true);

    var api_endpoint = "user_boards/get_by_token";
    var headersData = {"Authorization": APIService.getHeaders().authorization};
    var paramsData = {"token": props.token};

    useEffect(() => {
        axios.get(APIService.API_URL + api_endpoint, {
            params: paramsData,
            headers: headersData
        }).then((response) => {

            if (response.data.errors) {

                Swal.fire({
                    icon: 'info',
                    title: 'Oops',
                    html: '<span style="font-weight: 300;">' + response.data.errors.message[0] + '</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    window.location.assign("/");
                });

            } else {
                updateBoardData(response.data.user_board);
                setLoading(false);
            }

        }).catch((error) => {
            APIService.errorResponse(error);
            return [];
        });


    }, [props]);


    if (loading) {
        return <div/>;
    }

    Font.register({family: 'Poppins', format: "truetype", src: Poppins});
    Font.register({family: 'PoppinsBold', format: "truetype", src: PoppinsBold});

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#efefef",
            color: "#000",
        },
        container: {
           margin: "auto",
           width: "60%",
            padding: 15,
        },
        section: {
            margin: 5,
            padding: 5,
        },
        title: {
            fontSize: "36px",
            color: "#000",
            fontWeight: "600",
            marginBottom: "10px",
            fontFamily: 'PoppinsBold',
        },
        text: {
            fontSize: "10px",
            color: "#000",
            fontFamily: 'Poppins',
        },
        label: {
            fontSize: "7px",
            padding: "3px",
            backgroundColor: "#000",
            color: "#fff",
            textTransform: "uppercase",
            marginBottom: "10px",
            fontFamily: 'Poppins',
            borderRadius: "25px",
            width: "25%",
            textAlign: "center",
        },
        viewer: {
            width: window.innerWidth, //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
        },
        footer: {
            fontSize: "9px",
            color: '#444444',
            textAlign: "center",
            fontFamily: 'Poppins',
            marginTop: '20px',
        }
    });

    Font.registerHyphenationCallback(word => [word]);

    return <PDFViewer style={styles.viewer}>
        <Document>

            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                        <View style={styles.section} wrap={false}>
                            <Text style={styles.title}>{boardData.user_board.title}</Text>
                            <Text style={styles.text}>{boardData.user_board.description}</Text>
                        </View>
                        {boardData.board_cards.length > 0 && boardData.board_cards.map((c, index) => (
                            c.text.trim() !== '' &&
                            <View style={styles.section} wrap={false}>
                                <Text style={styles.label}>{c.card_type.label}</Text>
                                <Text style={styles.text}>{c.text}</Text>
                            </View>
                        ))}
                        <View style={styles.section} wrap={false} >
                            <Text style={styles.footer}>Write and share elegant explainers with NARRA (www.NARRA.cc).</Text>
                        </View>
                </View>
            </Page>
        </Document>
    </PDFViewer>

};

