import React, {useEffect, useState} from "react";
import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

export default function Portfolio(props) {

    const [loading, setLoading] = useState(true);
    const [userPortfolio, updateUserPortfolio] = useState();
    const [publishedStatus, updatePublishedStatus] = useState();

    window.updateUserPortfolio = updateUserPortfolio;

    var api_endpoint = "user_portfolio/view";
    var paramsData = {"token": props.token};
    var headersData = {};
    if (props.view_mode !== "view") {
        api_endpoint = "user_portfolio/get";
        headersData = {"Authorization": APIService.getHeaders().authorization};
        paramsData = {};
    }

    useEffect(() => {

        axios.get(APIService.API_URL + api_endpoint,
            {params: paramsData, headers: headersData})
            .then((response) => {

                if (response.data.errors) {
                    Swal.fire({
                        icon: 'info',
                        title: 'Oops',
                        html: '<span style="font-weight: 300;">' + response.data.errors.message[0] + '</span>',
                        color: '#000000',
                        customClass: {
                            confirmButton: 'btn black-btn button-text m-1',
                            title: 'swal-text'
                        },
                        buttonsStyling: false
                    }).then((result) => {
                        window.location.assign("/");
                    });

                } else {
                    updateUserPortfolio(response.data.user_portfolio);
                    updatePublishedStatus(response.data.user_portfolio.profile.published);
                    setLoading(false);
                }

            }).catch((error) => {
            APIService.errorResponse(error);
        });

    }, []);

    if (loading) {
        return <div/>;
    }

    const onClickBoard = (e) => {
        window.open("/publish/" + e);
    };

    const editBoard = (e) => {
        window.open("/craft/" + e);
    };


    const viewPortfolio = () => {
        window.open("/portfolio/view/" + window.CurrentUserToken);
    };

    const previewPortfolio = () => {
        window.open("/portfolio/preview");
    };

    const showSetPortfolioModal = () => {
        window.setPortfolioModalFormFields({data: userPortfolio});
    };

    const publishPortfolio = () => {

        var portfolio_data = {publish: !publishedStatus};
        var api_endpoint = "user_portfolio/publish";

        axios.post(APIService.API_URL + api_endpoint, portfolio_data,
            {headers: {"Authorization": APIService.getHeaders().authorization}}).then((response) => {

            if (response.data.errors) {

            } else {
                updatePublishedStatus(response.data.user_portfolio.published);
                var pubSt = 'published';
                if (!response.data.user_board.published) {
                    pubSt = 'unpublished';
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<span style="font-weight: 300;">' +
                        '<p>Your portfolio is now ' + pubSt + '.</p>' +
                        '</span>',
                    color: '#000000',
                    customClass: {
                        confirmButton: 'btn black-btn button-text m-1',
                        title: 'swal-text'
                    },
                    buttonsStyling: false
                });

            }

        }).catch((error) => {
        });
    }

    return (
        <div className={"portfolio " + props.view_mode}>
            {props.view_mode == "set" && <span>
                <div className="d-flex">
                <div className="my-auto">
                    <h4 className="mb-0 page-title">Craft portfolio</h4>
                </div>
                <div className="d-none d-lg-block ms-auto">
                    <button className="btn btn-sm btn-outline-secondary button-text me-2" data-bs-toggle="modal"
                            data-bs-target="#set-portfolio-modal"
                            onClick={showSetPortfolioModal}>
                        <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                        EDIT
                    </button>
                    {!publishedStatus &&
                    <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={previewPortfolio}>
                        <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                        PREVIEW
                    </button>}
                    {!publishedStatus &&
                    <button className="btn btn-sm yellow-btn button-text" onClick={publishPortfolio}>
                        <i className="material-icons-outlined icon opacity-10">publish</i>&nbsp;PUBLISH
                    </button>}
                    {publishedStatus &&
                    <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={publishPortfolio}>
                        <i className="material-icons-outlined icon opacity-10">unpublished</i>&nbsp;UNPUBLISH
                    </button>}
                    {publishedStatus &&
                    <button className="btn btn-sm yellow-btn button-text" onClick={viewPortfolio}>
                        <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                        VIEW PAGE
                    </button>}
                </div>
                </div>
                <div className="d-block d-lg-none mt-3">
                <button className="btn btn-sm btn-outline-secondary button-text me-2" data-bs-toggle="modal"
                        data-bs-target="#set-portfolio-modal"
                        onClick={showSetPortfolioModal}>
                    <i className="material-icons-outlined icon opacity-10">settings</i>&nbsp;
                    EDIT
                </button>
                {!publishedStatus &&
                <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={previewPortfolio}>
                    <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                    PREVIEW
                </button>}
                {!publishedStatus &&
                <button className="btn btn-sm yellow-btn button-text" onClick={publishPortfolio}>
                    <i className="material-icons-outlined icon opacity-10">publish</i>&nbsp;PUBLISH
                </button>}
                {publishedStatus &&
                <button className="btn btn-sm btn-outline-secondary button-text me-2" onClick={publishPortfolio}>
                    <i className="material-icons-outlined icon opacity-10">unpublished</i>&nbsp;UNPUBLISH
                </button>}
                {publishedStatus &&
                <button className="btn btn-sm yellow-btn button-text" onClick={viewPortfolio}>
                    <i className="material-icons-outlined icon opacity-10">visibility</i>&nbsp;
                    VIEW PAGE
                </button>}
            </div>
                <hr className="horizontal dark"></hr>
            </span>}
            <div className="row mt-5">
                <div className="col-xl-6 col-lg-4 me-3 mb-3 wow fadeInLeft" data-wow-delay=".4s"
                     data-wow-duration="2s">
                    <div className="brand-name">{userPortfolio.profile.name}</div>
                </div>
                <div
                    className="col-xl-5 col-lg-7 d-flex flex-column wow fadeInRight" data-wow-delay=".4s"
                    data-wow-duration="2s">
                    <div className="brand-tagline pt-0"><p>{userPortfolio.profile.bio}</p></div>
                </div>
            </div>

            <div className="my-auto mt-3">
                <h4 className="mb-0 page-title">My portfolio</h4>
            </div>
            <hr className="horizontal dark"></hr>
            <div className="row row mt-lg-4 mt-2">
                {userPortfolio.portfolio && userPortfolio.portfolio.length > 0 && userPortfolio.portfolio.map((c, index) => (
                    <div key={index} className="col-lg-4 col-md-6 mb-4 wow zoomIn" data-wow-delay=".4s"
                         data-wow-duration="2s">
                        <div className="content-box-2">
                            <div className="d-flex">
                                <div className="my-auto">
                                    <h6 className="board-name" onClick={() => onClickBoard(c.token)}>{c.title}</h6>
                                </div>
                                <div className="ms-auto">
                                    <button className="btn btn-link text-secondary" data-bs-toggle="dropdown"
                                            id="dropdownMenuLink2">
                                        <i className="fa fa-ellipsis-v text-lg"></i>
                                    </button>
                                    <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink2">
                                        <li><a onClick={() => onClickBoard(c.token)} className="dropdown-item"
                                               href="#">View</a></li>
                                        {props.view_mode == "set" &&
                                        <li><a onClick={() => editBoard(c.token)} className="dropdown-item"
                                               href="#">Edit</a></li>}
                                    </ul>
                                </div>
                            </div>
                            <p>{c.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}