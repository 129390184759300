import React, {useState} from "react";
import CardSuggestions from "./CardSuggestions";

export default function ShowSuggestionsModal() {

    const [cardID, setCardID] = useState("");
    const [authMode, setAuthMode] = useState("");
    const [boardMode, setBoardMode] = useState("");

    const onClickModalClose = () => {
        setCardID("");
    };

    const setSuggestionsModalCardID = (data) => {

        setCardID(data.data.id);
        setAuthMode(data.auth_mode)
        setBoardMode(data.mode)

    }

    window.setSuggestionsModalCardID = setSuggestionsModalCardID;

    return (

        <div className="modal fade" tabIndex="-1" role="dialog" id="show-suggestions-modal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Suggestions</h5>
                        <button className="close-btn btn btn-icon" type="button" data-bs-dismiss="modal" onClick={onClickModalClose}>
                            <i className="material-icons-round">cancel</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        {cardID && <CardSuggestions card_id={cardID} auth_mode={authMode} mode={boardMode}/>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-outline-secondary button-text"  data-bs-dismiss="modal"
                                onClick={onClickModalClose}>Close
                        </button>

                    </div>
                </div>
            </div>
        </div>

    )
}