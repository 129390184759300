import React, {useState, useEffect} from "react";

import APIService from "../../services/api.service";
import axios from "axios";
import Swal from "sweetalert2";

const Unlock = (props) => {

    useEffect(() => {

        var url = new URL(window.location);
        var unlock_token = url.searchParams.get("unlock_token");

        if (unlock_token) {

            return axios
                .get(APIService.AUTH_URL + "unlock", {
                    'params': {
                        'unlock_token': unlock_token
                    }
                }).then((response) => {

                    if (response.data.errors) {

                        var respStr = APIService.errorsArray(response.data.errors);
                        setMessage(respStr);
                        setLoading(false);
                        setSuccessful(false);
                    } else {
                        setLoading(false);
                        setSuccessful(true);
                        setMessage("");
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            html: '<span style="font-weight: 300;">Your account is now unlocked. Please sign in.</span>',
                            color: '#000000',
                            customClass: {
                                confirmButton: 'btn black-btn button-text m-1',
                                title: 'swal-text'
                            },
                            buttonsStyling: false
                        });
                    }

                }).catch((error) => {
                    var resp = APIService.errorResponse(error);
                    setMessage(resp);
                    setLoading(false);
                    setSuccessful(false);
                });


        } else {
            window.location.assign("/resend-unlock");
        }

    }, []);

    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    return (

        <section>
            <div className="page-header ">
                <div className="container">
                    <div className="row">

                        <div
                            className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column m-auto">
                            <div className="card card-plain">
                                <div className="card-header text-center mb-2">
                                    <h5 className="font-weight-bolder">Unlock account</h5>
                                </div>
                                <div className="card-body mt-2 mb-2">
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    {message && (
                                        <div className="form-group">
                                            <div
                                                className={
                                                    successful ? "text-success mb-3 text-center" : "text-primary mb-3 text-center"
                                                }
                                                role="alert"
                                            >
                                                <div dangerouslySetInnerHTML={{__html: message}}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="card-footer text-center pt-4 mt-2">
                                    <p><a href="/login" className="font-weight-bold">Sign
                                        in</a></p>
                                    <p><a href="/resend-unlock"
                                          className="font-weight-bold">Resend unlock
                                        email</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Unlock;
